import React, { useEffect } from "react";
import Navbar from "../Navbar/Navbar"; 
import Slider from "../Slider/Slider";
import LandingPage from "../LandingPage/LandingPage";
import Footer from "../Footer/Footer";
const Home = () => {
  useEffect(() => {
    localStorage.removeItem("filterselected");
    localStorage.removeItem("priceFilter");  
    localStorage.removeItem("selectedFilters");  
  },[])
  return (
    <>
      <div className="container-fluid pl-0 pr-0" style={{overflow:'hidden'}}>
        {/* <Navbar /> */} 
        <LandingPage />
        <Slider />
        <Footer />
      </div>
    </>
  )
}
export default Home; 