import React, { useEffect , useState } from "react";
import Navbar from "../Navbar/Navbar"; 
import Sidebar from "../Dashboard/Sidebar/Sidebar";
import SaleCompletionCard from "./SaleCompletionCard";
import axios from "axios";
import Env from "../Environment/Env";
import { getAsset } from "../utils/helper";

const DashboardPurchases=()=>{

    const [AllProductUser, setAllProductUser] = useState([]);
    const [CancelSellOrderId, setCancelSellOrderId] = useState("");
    
useEffect(()=>{
getAllProduct();
},[])

    const cancelSellOrder = () => {
        console.log("abc===>>>", CancelSellOrderId);
        axios.delete(`${Env.server}/api/order/CancelSelingByUser/${CancelSellOrderId}`)
        .then((res)=>{
            console.log("abc===>>>", res.data);
            getAllProduct();

        })
        .catch((err) => {
            console.log("err===>>>", err);
        })
    }

    const getAllProduct=()=>{
        axios.get(`${Env.server}/api/order/getall`)
            .then((res) => { 
                console.log("abc===>>", res.data.orders);

                let orders = res.data.orders;
                const clientId = localStorage.getItem("id"); // Assuming localStorage.getItem("id") fetches the client ID

                // Filter orders based on clientid
                const filteredOrders = orders.filter(order => order.clientid === clientId);

                
                // Merge all products from filteredOrders into one array
                const allProducts = filteredOrders.flatMap(order => order.product);
                
                console.log("abcbcbcbbcbc=====", allProducts);
                setAllProductUser(allProducts);
            })
            .catch((err) => {
                console.log("err====>>>", err)
            })
    }


    return(
        <>
        
        <Navbar />
            <div className="container-fluid">
                <div className="row"> 
                    <div className="col-md-3">
                        <Sidebar />
                    </div> 
                    <div className="col-md-8 mt-5 ms-md-5">
                        <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-3"> 
                            {AllProductUser.map((data, index) => (
                                <div key={index} className="col">
                                    <div className="card" style={{ height: '100%', backgroundColor: "white", border: "1px solid grey" }}>
                                        <img style={{height:"300px"}} src={getAsset(data.data && data.data.image[0])} className="card-img-top img-fluid" alt="Product Image" style={{ maxHeight: '150px', objectFit: 'contain' }} />
                                        <div className="card-body d-flex flex-column justify-content-between">
                                            <div>
                                                <h5 className="card-title"> Product: &nbsp;{data.data.name} </h5>
                                                <p className="card-text">Price: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{data.data.price}</p>
                                            </div>
                                            <button className="btn btn-danger mt-4" 
                                                data-toggle="modal"
                                                data-target="#exampleModalLong1" onClick={() => setCancelSellOrderId(data.data._id)}>Cancel</button>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade" id="exampleModalLong1" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLongTitle">Cancel Selling</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <h4>Do ylu want to cancel selling this product?</h4>
                        </div>

                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button type="button"
                                class="btn btn-danger"
                                data-dismiss="modal"
                                onClick={cancelSellOrder}  >Confirm</button>
                        </div>
                    </div>
                </div>
            </div>
        
        </>
    )
}
export default DashboardPurchases;
