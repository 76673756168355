import React, { useEffect, useState } from "react";
import { getAsset } from "../utils/helper";
// import "./Banar.css"
import Navbar from "../Navbar/Navbar";
import { Link } from "react-router-dom";
import axios from "axios";
import Env from "../Environment/Env"; 
import { useNavigate } from "react-router-dom";
import {Paper, Grid, Card, CardContent} from '@mui/material'
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faComment } from '@fortawesome/free-solid-svg-icons';
import Loader from './../loader.gif';

const AmPage = () => {
    const { Model , Accessory } = useParams();
    const navigate = useNavigate();
    const [alldata , setAllData] = useState([]);
    const [allProductData1, setAllProductData1] = useState([]);
    const [allProductData, setAllProductData] = useState([]);
    const [FilteredData, setFilteredData] = useState([]);
    const [selectedFilters, setSelectedFilters] = useState([]);
    const [SelectedId, setSelectedId] = useState();
    const [isLoading,setIsLoading] = useState(false);


    const [MinRange, setMinRange] = useState(0);
    const [MaxRange, setMaxRange] = useState(localStorage.getItem("priceFilter") || 6000);
    const [selectedRange, setSelectedRange] = useState(localStorage.getItem("priceFilter") || 6000);


    const handleRangeChange = (event) => {
        const range = parseInt(event?.target?.value);
        localStorage.setItem("priceFilter", range);
        setSelectedRange(range);
    };

    useEffect(()=>{
        applyFilterProper();
    },[selectedRange,MinRange,selectedFilters]);

    const applyFilterProper = async() =>{
        setIsLoading(true);
        let url = `${Env.server}/api/subsubcatagory/GetByFilterForRetailByModel?min=${MinRange}&max=${selectedRange}&subcatagory=${encodeURIComponent(Model)}`;
        if(selectedFilters.length === 0 && selectedRange === MaxRange){
            getAllByNmeAcc();
            return;
        }
        console.log('aaaaaaaaaaaaaaaaaaaa',selectedFilters);
        selectedFilters.map(filter=>{
            if(filter.type === "Manufacturer"){
                url+= `&manufacturerName=${filter.var}`
            }
            if(filter.type === "Color"){
                url+= `&color=${filter.var}`
            }
            if(filter.type === "Variant"){
                url+= `&variantName=${filter.var}`
            }
        })
        await axios.get(url)
        .then((res)=>{
            console.log('aaaaaaaaaaaaaaaaaaa',res.data.data);
            setAllProductData(res.data.data);
        })
        .catch((err)=>console.log('aaaaaaaaaaaaaaaaaaaa',err));
        setIsLoading(false);
    }


  const handleFilter=()=>{
    //   localStorage.setItem("priceFilter", MaxRange);
      // reload page
        // window.location.reload();
    }
    

    const getAllByNmeAcc = async() => {
        setIsLoading(true);
        await axios
            .get(`${Env.server}/api/subsubcatagory/GetModelByModel/${Model}`)
            .then((res) => {
                console.log("Abc=====", res.data.subsubcatagories); 
                setAllData(res.data.subsubcatagories);

                const prodArr = res.data.subsubcatagories;
                console.log('fffff',prodArr);
                const maxNum = Math.max(...prodArr.map(product => product.productPrice));
                const minNum = Math.min(...prodArr.map(product => product.productPrice));
                if(minNum === maxNum){
                    setMinRange(0);
                }else{
                    setMinRange(minNum === Infinity ? 0 : minNum);
                }
                setMaxRange(maxNum === -Infinity ? 0 : maxNum);
                if(!localStorage.getItem("minRange")){
                    console.log('I just set the value to be',MinRange);
                }else{
                    setMinRange(localStorage.getItem("minRange"));
                }
                setSelectedRange(maxNum === -Infinity ? 0 : maxNum);

                if (localStorage.getItem("filterselected") === 0) {
                    console.log("");
                } else {
                    const savedFilters = localStorage.getItem('selectedFilters');
                    if (savedFilters) {
                        setSelectedFilters(JSON.parse(savedFilters));
                    }
                }

                console.log("res.data.subsubcatagories====", res.data.subsubcatagories)
                setAllProductData(res.data.subsubcatagories)
                setAllProductData1(res.data.subsubcatagories)
                let dataAll = res.data.subsubcatagories

                console.log('Selected Range ==>',selectedRange);
                let filteredItems = dataAll && dataAll.filter(item => parseInt(item.productPrice) <= selectedRange && parseInt(item.productPrice) >= MinRange);
                console.log("muzzamal====", filteredItems)
                setAllProductData(filteredItems)

                let data = res.data.subsubcatagories;
                // console.log("hhhhhhhhhhhhhhhh======", data);
                const combinedFilters = {};

                data.forEach((product) => {
                    if (product.filter) {
                        product.filter.forEach((filterCategory) => {
                            Object.entries(filterCategory).forEach(([key, values]) => {
                                if (values.length === 0) return; // Skip if no data

                                if (!combinedFilters[key]) {
                                    combinedFilters[key] = [];
                                }
                                values.forEach((value) => {
                                    let existingFilter = combinedFilters[key].find(
                                        (existing) => existing.var === value
                                    );
                                    if (!existingFilter) {
                                        combinedFilters[key].push({ var: value, id: [product._id] });
                                    } else {
                                        existingFilter.id.push(product._id);
                                    }
                                });
                            });
                        });
                    }
                });

                console.log("Combined filters:", combinedFilters);
                setFilteredData(combinedFilters);
                // handleFiltersSelected();
            })
            .catch((err) => {
                console.log("err====", err);
            })
            setIsLoading(false);
    }

    useEffect(()=>{
        getAllByNmeAcc();
    },[])

    const handleCardClick = (selectedProduct, quantity) => {
        if (quantity === undefined) {
            quantity = 1;
        }
        console.log("quantity------", quantity);
        let products = JSON.parse(localStorage.getItem('selectedProducts')) || [];

        // Check if the selected product already exists in the array
        const existingProductIndex = products.findIndex(product => product._id === selectedProduct._id);

        if (existingProductIndex !== -1) {
            // If the product already exists, update it including quantity
            products[existingProductIndex].quantity = quantity;
        } else {
            // If the product does not exist, add it to the array including quantity
            selectedProduct.quantity = quantity;
            products.push(selectedProduct);
        }

        // Store the updated products array in local storage
        localStorage.setItem('selectedProducts', JSON.stringify(products));
    }

    const [isSelected, setIsSelected] = useState(false);

    const handleCheckboxChange = () => {
        setIsSelected(!isSelected);
    };


    useEffect(()=>{
        handleFiltersSelected();
    },[selectedFilters])

    const handleFiltersSelected = ()=>{
        console.log('here is the data of need ===>>',selectedFilters);
        if(selectedFilters.length > 0){
        let outputArray = [];
            selectedFilters.forEach((filter) => {
                const filterid = filter.id[0];
                outputArray = [...outputArray,...allProductData.filter(prod=>filterid.includes(prod._id))];
            });
            setAllProductData(outputArray);
        }
    }
    
    useEffect(()=>{
        localStorage.setItem('selectedFilters',[]);
    },[Model]);



    const handleCheckboxClick = (filterVar, productId,filterName) => {
        setSelectedFilters(prevFilters => {
            const selectedIndex = prevFilters.findIndex(filter => filter.var === filterVar);
            
            if (selectedIndex === -1) {
                const newFilters = [...prevFilters, { var: filterVar, id: [productId],type:filterName }];
                console.log('Here is the data vvv=>>',newFilters);
                return newFilters;
            } else {
                const newFilters = prevFilters.filter((_, index) => index !== selectedIndex);
                return newFilters;
            }
        });
    };



    // useEffect(() => {
        
    // }, [selectedFilters]);


    const [productQuantities, setProductQuantities] = useState({});


    const increaseQuantity = (productId) => {
        setProductQuantities(prevQuantities => ({
            ...prevQuantities,
            [productId]: (prevQuantities[productId] || 0) + 1
        }));
    };

    // Function to handle decreasing the quantity for a specific product
    const decreaseQuantity = (productId) => {
        if (productQuantities[productId] && productQuantities[productId] > 0) {
            setProductQuantities(prevQuantities => ({
                ...prevQuantities,
                [productId]: prevQuantities[productId] - 1
            }));
        }
    };

    // Function to handle changing the quantity directly for a specific product
    const handleQuantityChange = (productId, event) => {
        const value = parseInt(event?.target?.value);
        if (!isNaN(value) && value >= 1) {
            setProductQuantities(prevQuantities => ({
                ...prevQuantities,
                [productId]: value
            }));
        }
    };


    return (
        <>
       
            <Navbar />      
        



        <div className="container-fluid">
          <p className="mt-3">Select your device model to discover a wide range of compatible accessories tailored just for you. Choose your device model to explore our extensive collection of accessories designed to enhance your experience.
          </p>
        </div>

        <div className="container-fluid">
        <div className="row">
            
        <div className="col-lg-3 col-md-2 pt-4 mb-3" style={{}}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div>
                    <input
                        type="range"
                        min={MinRange}
                        max={MaxRange + 1}
                        step="1"
                        value={selectedRange}
                        onChange={handleRangeChange}
                    />
                    </div>
                    <div style={{  justifyContent: 'space-between', marginTop: '10px' }}>
                        <input type="text" style={{ width: '35%' }} value={MinRange} onChange={(e)=> {
                            setMinRange(e.target.value)
                            localStorage.setItem("minRange",e.target.value);
                            }} placeholder="" /> - to -
                        <input type="text" style={{ width: '35%' }} value={selectedRange} onChange={(e) => setSelectedRange(e.target.value)} placeholder="" />
                        <button onClick={handleFilter} style={{ width: '50%' , borderRadius:"17px" , border:"1px solid transparent", backgroundColor: '#DB241B', color: 'white', padding: '5px', marginTop: '8px' }} >Filter</button>
                    </div>
                </div>

                        <div>
                            {/* Map over the filtered data and create checkboxes */}
                            {FilteredData && Object.entries(FilteredData).map(([filterName, filterValues]) => (
                                // Check if filterName is not "Variant"
                              filterName !== "ProductPrice" &&
                                <div className="box w-75 my-4">
                                    <h5 className="text-black border-top pt-3">{filterName}</h5>
                                    <div className="p-2">
                                        {/* Map over filter values and create checkboxes */}
                                        {filterValues.map(filter => (
                                            <div key={filter.var} className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    id={filter.var}
                                                    checked={selectedFilters.some(selectedFilter => selectedFilter.var === filter.var)}
                                                    onClick={() => handleCheckboxClick(filter.var, filter.id,filterName)}
                                                />
                                                <label className="form-check-label" style={{textWrap: 'nowrap'}} htmlFor={filter.var}>
                                                    {filter.var}
                                                </label>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ))}
                        </div>
              {/* <div>
                {FilteredData && Object.entries(FilteredData).map(([filterName, filterValues]) => (
                  <div className="box w-75 my-4">
                    <h5 className="text-black border-top pt-3">{filterName}</h5>
                    <div className="p-2">                
                      {filterValues.map(filter => (
                        <div key={filter.var} className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id={filter.var}
                            checked={selectedFilters.some(selectedFilter => selectedFilter.var === filter.var)}
                            onClick={() => handleCheckboxClick(filter.var, filter.id)}
                          />
                          <label className="form-check-label" htmlFor={filter.var}>
                            {filter.var}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>  */}
              {/* <p style={{ fontWeight: 'bold' }}>Browse by Category</p>
              <ul style={{ listStyleType: 'none' }}>
                {UniqueTypes && UniqueTypes.map((data, index) => (
                  <>
                    <Link to={`/AmPage3/${data}`}>  <li>{data}</li></Link>
                  </>
                ))}

              </ul> */}
            </div>
            


        <div className="col-lg-9">
                <div className="container">
                    <div className="row">
                    <h1 className=" " style={{fontFamily: "poppins", fontWeight: "bold"}}>Accessories</h1>
                    {
                                isLoading ? 
                            <div style={{display:'flex',justifyContent:'center',alignItems:'center',minHeight:'70vh'}} >
                                <img src={Loader} alt="Loading..." width={120} height={120} />
                            </div>:
                            <>
                                {allProductData.map((card, index) => (
                                    <div 
                                        key={index}
                                        className="col-6 col-sm-6 col-md-3 col-lg-3"
                                       
                                    >
                                        <Link to={`/Checkout/${card._id}`} >
                                        <div className="card mb-4 align-items-center pt-4 pr-2 pl-2" style={{ backgroundColor: "white", cursor: 'pointer' }}>
                                            
                                            <div className="view overlay">
                                                <img className="card-img-top"  style={{height: "110px", width: "110px", border: "none"}} src={getAsset(card && card.imageSubsubCatagory && card.imageSubsubCatagory[0])} alt="Card image cap" />
                                                <a href="#!">
                                                    <div className="mask rgba-white-slight"></div>
                                                </a>
                                            </div>

                                            <div className="row align-items-center pt-2">
                                {card.subsubcatagory.split(" ").length > 8 ? (
                                    <h5 style={{fontSize: '13px', lineHeight:"18px"}} className="card-text text-left">
                                      {card.subsubcatagory.split(" ").slice(0, 8).join(" ")}...
                                    </h5>
                                  ) : (
                                    <h5 style={{fontSize: '13px', lineHeight:"18px"}} className="card-text text-left">
                                      {card.subsubcatagory}
                                    </h5>
                                  )}
 {card.subcatagory && <div>{card.subcatagory}</div>}
<div style={{ display: 'flex', alignItems: 'center', margin: "4px 0px" }}>
            <FontAwesomeIcon icon={faStar} style={{ color: '#CCDF67', width: '18px', height: "auto", margin: '0px' }} />
            <FontAwesomeIcon icon={faStar} style={{ color: '#CCDF67', width: '18px', height: "auto", margin: '0px' }} />
            <FontAwesomeIcon icon={faStar} style={{ color: '#CCDF67', width: '18px', height: "auto", margin: '0px' }} />
            <FontAwesomeIcon icon={faStar} style={{ color: '#CCDF67', width: '18px', height: "auto", margin: '0px' }} />
            <FontAwesomeIcon icon={faStar} style={{ color: '#CCDF67', width: '18px', height: "auto", margin: '0px' }} />
        </div>

                                  <h5 style={{fontSize: '13px'}} className="card-title text-left">£{card.productPrice}</h5>
                                           
                                  <div style={{ display: 'flex' }}>
                                    <img src="https://ik.imagekit.io/vhfsx9xkeh/Ok.png?updatedAt=1712168587135" width="8%" height="8%" />
                                    <h5 style={{fontSize: '13px', lineHeight:"18px", marginLeft: '5px'}} className="card-text text-left">
                                   {card.qty === 0 ? <span style={{fontSize: '13px' , color:"red"}} className="card-text text-left">Out of Stock</span> : <span style={{fontSize: '13px' , color:"green"}} className="card-text text-left">In Stock</span>}
                                                          
                                    </h5>

                                    </div>

                                            </div>

                                            <div style={{ marginLeft: "10px", marginRight: "10px" }}>
                    {/* <div className="input-group">
                      <button className="btn btn-outline-secondary" type="button" onClick={() => decreaseQuantity(card._id)}>-</button>
                      <input type="text" className="form-control" value={productQuantities[card._id] || 1} onChange={(e) => handleQuantityChange(card._id, e)} readOnly />
                      <button className="btn btn-outline-secondary" type="button" onClick={() => increaseQuantity(card._id)}>+</button>
                    </div> */}
                                            </div> 

                                            {/* <div className="col-md-12 mt-3">
                                                <div className="row align-items-center">
                                                    
                                                    <div className="col-md-12">
                                                    <buton className="btn " style={{ backgroundColor: '#DB241B', color: "white", cursor: "pointer", width: "100%" }} onClick={() => {
                                                        handleCardClick(card, productQuantities[card._id])
                                                        navigate("/Cart");
                                                    }}>Buy</buton>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div> */}
                                            <br />
                                        </div>
                                        </Link>
                                    </div>
                                ))}
                                </>
                                }

                    </div>
                </div>
               
        </div>

          </div>
        </div>           
        </>
    )
}
export default AmPage;


