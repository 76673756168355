import React from "react";
import "./Footer.css"
import Env from "../Environment/Env";
import { Link } from "react-router-dom";
import Environment from "../Environment/Env";
// import Env from "../Environment/Env";
const Footer = () => {
  return (
    <>
      {/* <div className="bg-white" >
        <footer class="bg-body-tertiary  bg-black   container text-lg-start" style={{ backgroundColor: "black !important" }} >
          <div class="row bg-white">


            <div class="col-lg-3 col-md-6 mb-4 mb-md-0 pt-4" >
              <h5 class="text-uppercase coler">About</h5>
              <ul class="list-unstyled mb-0" >
                <li>
                  <Link to="" className="coler" > About Us  </Link>
                </li>
                <li>
                  <Link to="" className="coler" > Franchising  </Link>
                </li>
                <li>
                  <Link to="" className="coler" > MobileBitz Blog  </Link>
                </li>
              </ul>
            </div>
            <div class="col-lg-3 col-md-6 mb-4 mb-md-0 pt-4 ">

              <h5 class="text-uppercase mb-0" className="coler">SUPPORT</h5>

              <ul class="list-unstyled">
                <li>
                  <Link to="" className="coler" > Find a Store  </Link>
                </li>
                <li>
                  <Link to="" href=" " className="coler">Contact Us</Link>
                </li>
                <li>
                  <Link to="" className="coler" > FAQ  </Link>
                </li>

              </ul>
            </div>
            <div class="col-lg-3 col-md-6 mb-4 mb-md-0 pt-4">
              <h5 class="text-uppercase coler">Legal</h5>
              <ul class="list-unstyled mb-0 stl" >
                <li>
                  <Link to="" className="coler" > Terms and Conditions  </Link>
                </li>
                <li>
                  <Link to="" className="coler" > Cookies Policy  </Link>
                </li>
                <li>
                  <Link to="" className="coler" > Privacy Policy  </Link>
                </li>
              </ul>
            </div>



            <div class="col-lg-3 col-md-6 mb-4 mb-md-0 d-flex justify-content-center align-items-center">
              <ul class="list-inline social-icons">
                <li class="list-inline-item">
                  <a href=" " className="coler">
                    <img src="https://png.pngtree.com/png-clipart/20230401/original/pngtree-three-dimensional-instagram-icon-png-image_9015419.png" alt="" style={{ width: "30px", height: "30px" }} />
                  </a>

                </li>
                <li class="list-inline-item">
                  <a href=" " className="coler">
                    <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/b8/2021_Facebook_icon.svg/1024px-2021_Facebook_icon.svg.png" alt="" style={{ width: "30px", height: "30px" }} />
                  </a>
                </li>
                <li class="list-inline-item">
                  <a href=" " className="coler">
                    <img src="https://cdn-icons-png.flaticon.com/512/4138/4138134.png" alt="" style={{ width: "30px", height: "30px" }} />
                  </a>
                </li>
                <li class="list-inline-item">
                  <a href=" " class="text-body">
                    <img src="https://cdn3.iconfinder.com/data/icons/2018-social-media-logotypes/1000/2018_social_media_popular_app_logo_twitter-512.png" alt="" style={{ width: "30px", height: "30px" }} />
                  </a>
                </li>
              </ul>
            </div>

          </div>
          <div class="text-center p-3 bg-white">
            <a href="https://www.websiteaccess.co.uk/">Designed by Website Access</a>
            <a class="text-black" href="" >:  All rights reserved MobileBitz 2023. ©</a>
          </div>
        </footer>
        <div class="row justify-content-center ">
          <div class="col-md-2 pb-4">
            <img class="img-fluid " src="https://ik.imagekit.io/kjumiqz3x/icon-removebg-preview.png?updatedAt=1710236631837" alt="..." />
          </div>
        </div>
      </div > */}
      <div className="container-fluid footerbackground pr-0 pl-0">

        <div className="row pr-5 pl-5"> 
          <div className="col-lg-4 mb-4">
            <Link to='/'>
            <img src={Env.picLogo} style={{ width: '200px', height:"auto" }} alt='company logo' />
            </Link>
          </div>
          <div className="col-lg-2">
            <Link to='/Aboutus'><h4>About us</h4></Link>
            <ul style={{listStyle: 'none', padding: '0'}}>
              <Link to='/Cookies'><li>GDPR consent</li></Link> 
              <Link to='/Terms'> <li>Terms and Conditions</li></Link>
              <Link to='/Privacy'> <li>Privacy Policy</li> </Link>
              <Link to='/Faqs'> <li>FAQs</li> </Link>
            </ul>
          </div>
          <div className="col-lg-4">
            <h4>Contact us</h4>
            <ul style={{listStyle: 'none', padding: '0'}}>
              <li>{Env.address}</li>
              <li>{Env.phone}</li>
            </ul>
          </div> 
          <div className="col-lg-2">
            <div class="social-icons">
              <Link to={Env.facebook}>
              <div class="icon"><i class="bi bi-facebook"></i></div>
              </Link>
              <Link to={Env.instagram}>
              <div class="icon"><i class="bi bi-instagram"></i></div>
              </Link>
              <Link to={Env.twitter}>
              <div class="icon"><i class="bi bi-twitter"></i></div>
              </Link>
              <Link to={Env.linkedin}>
              <div class="icon"><i class="bi bi-linkedin"></i></div>
              </Link>
            </div> 
          </div>
        </div>

        <hr />

        <div className="row">
          <div style={{textAlign: 'center'}}>
            <p>Copyright&#169;2024 All rights Reserved</p>
          </div>
        </div>

      </div>
    </>
  )
}
export default Footer;