import React, { useState } from 'react';
import { Container, Row, Col, Button, Carousel } from 'react-bootstrap';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';



const CategorySlider = ({ NavbarData, getAsset, startIndex, maxIndexToShow }) => {
    const navigate = useNavigate();
    const itemsPerPage = 4;
    const [currentSlide, setCurrentSlide] = useState(0);

    const handleSelect = (selectedIndex) => {
        setCurrentSlide(selectedIndex);
    };

    const slides = [];
    for (let i = 0; i < NavbarData.length; i += itemsPerPage) {
        slides.push(NavbarData.slice(i, i + itemsPerPage));
    }

    return (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <div className="container-fluid">
                <div className="row align-items-center justify-content-between">
                    <div className="col-1">
                        <button style={{ background: 'none', border: 'none' }} onClick={() => handleSelect((currentSlide - 1 + slides.length) % slides.length)}>
                            <FaChevronLeft size={40} style={{ margin: '10px', color: '#555555' }} /> {/* Icon for previous */}
                        </button>
                    </div>
                    <div className="col-10">
                        <Carousel 
                            activeIndex={currentSlide} 
                            onSelect={handleSelect} 
                            indicators={false} 
                            controls={false}
                            interval={null}
                            slide={true} // Enable sliding
                        >
                            {slides.map((slide, slideIndex) => (
                                <Carousel.Item key={slideIndex}>
                                    <Container className="product-slider-container">
                                        <Row className="product-slider-row">
                                            {slide.map((item, index) => (
                                                <Col key={index} lg={3} md={3} className="product-card">
                                                    <div className="product-details">
                                                        <h3>{item.type}</h3>
                                                        <Button style={{ backgroundColor: 'black', border: 'black', color: 'white' }} onClick={() => navigate(`/AmPage3/${item.type}`)}>View Now</Button>
                                                    </div>
                                                    <div style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
                                                        <img src={getAsset(item.image[0])} style={{ width: '44%', height: 'auto' }} />
                                                    </div>
                                                </Col>
                                            ))}
                                        </Row>
                                    </Container>
                                </Carousel.Item>
                            ))}
                        </Carousel>
                    </div>
                    <div className="col-1">
                        <button style={{ background: 'none', border: 'none', float: 'inline-end' }} onClick={() => handleSelect((currentSlide + 1) % slides.length)}>
                            <FaChevronRight size={40} style={{ margin: '10px', color: '#555555' }} /> {/* Icon for next */}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CategorySlider;

