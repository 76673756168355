import { useEffect, useState } from "react";
import './slider.css';
import axios from "axios";
import Env from "../Environment/Env";
import { getAsset } from "../utils/helper"
import { Link } from "react-router-dom";

const Slider = () => {

    const [getData, setGetData] = useState([])
    const [startIndex, setStartIndex] = useState(0);

    useEffect(() => {
        getAllAdminProduct();
    }, []);
    const getAllAdminProduct = () => {
        axios.get(`${Env.server}/api/product/getAllProductOfAdmin`)
            .then((res) => {
                console.log("abc--===>>>", res.data.message);
                console.log("abc--===>>>", res.data.products);
                setGetData(res.data.products)
            })
            .catch((err) => {
                console.log("err===", err)
            })
    }
    const handleNext = () => {
        if (startIndex + 4 < getData.length) {
            setStartIndex(startIndex + 1);
        }
    };

    const handlePrev = () => {
        if (startIndex > 0) {
            setStartIndex(startIndex - 1);
        }
    };

  return (
        <>
            <h1>

            </h1>
        </>
    )
}
export default Slider;