import './Specification.css'
import { useEffect, useState } from "react";
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
import Slider from "../Slider/Slider";
import Env from "../Environment/Env";
import axios from 'axios';
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
// import Spec2 from './Spec2';
import { useParams } from 'react-router-dom';
import { getAsset } from '../utils/helper';
import Header from '../Header/Header';



const Specification = () => {
   
    return (
        <>

            <Header/>
            <Slider/>
            

            

            {/* --------------------------------------Sidebar------------------------------------ */}



            <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel" style={{ borderBottomLeftRadius: "50px", borderTopLeftRadius: "50px" }}>
                <div class="offcanvas-header">
                    <h5 id="offcanvasRightLabel"></h5>
                    <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div class="offcanvas-body ml-4">
                    <h1 style={{ color: "#071A44" }}>Item added to sell</h1>    
                    
                    <Link to="/Cart ">
                        <button type="button" class="btn btn-light border rounded-pill w-100  custom-button text-white btn-lg" style={{ background: "#071A44" }} onClick={''}>Complete Your Sale</button><br /><br />
                    </Link>
                    <button type="button"  class="btn btn-light border rounded-pill w-100  custom-button btn-lg"    >Add more items</button>
                </div>
            </div>

            <Footer />



        </>

    )
}
export default Specification;   