
import { useEffect } from "react";
import "./OrderComplete.css";
import { useParams } from "react-router-dom";
import axios from "axios";
import Env from "../Environment/Env"
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import { Link } from "react-router-dom";

const OrderComplete=()=>{
    let { id } = useParams();
    useEffect(() => {
        apicall();
    }, [])

    useEffect(() => {
        localStorage.removeItem("selectedProducts");
    }, [])


    const apicall = () => {
        console.log("abc====", id)
        axios.patch(`${Env.server}/api/order/paymentStatusUpdate/${id}`)
            .then((res) => {
                console.log("muzzamal", res.data);
            })
            .catch((err) => console.log(err));
    }

    return (
        <>
        <Navbar/>
            <body className="bbody body" style={{ marginBottom: "70vh" }}>
                <div class="contanier">
                <Link to="/">

                    <div class="button" style={{ marginTop: "100px" }}>
                        <div class="b1">Order Successfully Placed</div>
                        <div class="road"></div>
                        <div class="boxx">
                            <div class="package" id="package"></div>
                            <div class="lorry">
                                <div class="door">
                                    <div class="ld"></div>
                                    <div class="rd"></div>
                                </div>
                                <div class="head">
                                    <div class="clight">
                                        <img class="c1" src="http://pngimg.com/uploads/light/light_PNG14452.png" /> 
                                            </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Link>
                        
                </div>
            </body>
            <Footer/>

        </>
    )
}   
export default OrderComplete;