import React, { useEffect, useState } from "react";
import "./LandingPage.css"; // Import CSS file if needed
import Navbar1 from "../Navbar/Navbar1"; // Import Navbar1 component if needed
import { Link } from "react-router-dom"; // Import Link component for routing
import axios from "axios"; // Import axios for making HTTP requests
import Env from "../Environment/Env"; // Import Env for server URL
import { Container, Row, Col } from "react-bootstrap"; // Import Bootstrap components if needed

const AllTypes = () => {
  // State variables
  const [searchText, setSearchText] = useState(""); // Search input text
  const [navbarData, setNavbarData] = useState([]); // Original navbar data
  const [filteredNavbarData, setFilteredNavbarData] = useState([]); // Filtered navbar data based on search

  useEffect(() => {
    getAllNavbarData(); // Fetch initial data when component mounts
  }, []);

  // Function to fetch navbar data from server
  const getAllNavbarData = () => {
    axios
      .get(`${Env.server}/api/filters/gettype`)
      .then((res) => {
        setNavbarData(res.data.allType); // Set original navbar data
        setFilteredNavbarData(res.data.allType); // Initialize filtered data with all items
      })
      .catch((err) => {
        console.log("err====", err);
      });
  };

  // Function to handle search input change
  const handleSearchInputChange = (event) => {
    const searchText = event.target.value.toLowerCase();
    setSearchText(searchText);

    // Filter items based on search text
    const filteredData = navbarData.filter((item) =>
      item.type.toLowerCase().includes(searchText)
    );
    setFilteredNavbarData(filteredData);
  };

  return (
    <>
      <Navbar1 /> {/* Render Navbar1 component if needed */}

      {/* Search bar */}
      <h3 style={{ textAlign: "center", marginTop: "40px", fontFamily: 'cursive' }}>All Types</h3>
      <div style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>

        <input
          placeholder="Search"
          style={{ width: "300px", height: "30px" }}
          value={searchText}
          onChange={handleSearchInputChange} // Call handleSearchInputChange on input change
        />
      </div>

      {/* Display filtered or all items */}
      <Container className="mt-4">
        <Row xs={1} sm={2} lg={4}>
          {/* Mapping through filteredNavbarData to render each item as a card */}
          {filteredNavbarData.map((item, index) => (
            <Col key={index} className="mb-3">
              <Link to={`/AmPage3/${item.type}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                <div className="card" style={{ backgroundColor: '#F8F6ED' }}>
                  <div className="card-body text-center">
                    <h5 className="card-title">{item.type}</h5>
                  </div>
                </div>
              </Link>
            </Col>
          ))}
        </Row>
      </Container>
    </>
  );
};
export default AllTypes;







// import React, { useEffect, useState } from "react";
// import "./LandingPage.css";
// import star from "./star.png";
// import Navbar from "../Navbar/Navbar";
// import Navbar1 from "../Navbar/Navbar1";
// import { Link } from "react-router-dom";
// import axios from "axios";
// import Env from "../Environment/Env";
// import { getAsset } from "../utils/helper";
// import { Container, Row, Col, Card, Button, Carousel } from "react-bootstrap";

// import InputAdornment from "@mui/material/InputAdornment";
// import SearchIcon from "@mui/icons-material/Search";
// import { Paper, Grid, CardContent } from "@mui/material";
// import { BsStarFill } from "react-icons/bs";
// import {
//   TextField,
//   List,
//   ListItemButton,
//   ListItemText,
//   Popper,
//   IconButton,
//   ListItem,
//   Collapse,
//   Box,
//   Typography,
// } from "@mui/material";
// import MenuIcon from "@mui/icons-material/Menu";
// import ExpandLess from "@mui/icons-material/ExpandLess";
// import ExpandMore from "@mui/icons-material/ExpandMore";
// import { CardBody } from "react-bootstrap";
// import { useNavigate } from "react-router-dom";


// const AllTypes = () => {
//   const navigate = useNavigate();
//   const [searchText, setSearchText] = useState("");
//   const [options, setOptions] = useState(["Choose Options"]);
//   const [options1, setOptions1] = useState(["Choose Options"]);
//   const [GetAllModelsLanding, setGetAllModelsLanding] = useState([]);
//   const [anchorEl, setAnchorEl] = useState(null);
//   const [CardData, setcardData] = useState([]);
//   const [sidebarOpen, setSidebarOpen] = useState(false);
//   const [selectedCategory, setSelectedCategory] = useState(null);




//   const [ContentName, setContentName] = useState("");
//   const [imagesFront, setImagesFront] = useState([]);
//   const [image, setImages] = useState("");
//   const [DataNumber, setDataNumber] = useState(0);
//   const [selectedType, setSelectedType] = useState("");
//   const [UniqueTypes, setUniqueTypes] = useState([]);
//   const [responseImages, setResponseImages] = useState([]);
//   const [NewArrivalOne, setNewArrivalOne] = useState([]);
//   const [NewArrivalTwo, setNewArrivalTwo] = useState([]);
//   const [NewArrivalThree, setNewArrivalThree] = useState([]);
//   const [NewArrivalFour, setNewArrivalFour] = useState([]);
//   const [NavbarData, setNavbarData] = useState([]);



//   useEffect(() => {
//     getAllNavbarData();
//     getAllData();
//   }, [])


//   const getAllNavbarData=()=>{
//     axios.get(`${Env.server}/api/filters/gettype`)
//     .then((res) => {
//       console.log("allTypesData===", res.data.allType)
//       setNavbarData(res.data.allType)
     
//     })
//     .catch((err) => {
//       console.log("err====", err)
//     })
//   }

//   const getAllData = () => {
//     axios.post(`${Env.server}/api/retailnewarival/getAll`)
//       .then((res) => {
//         console.log("Abresc===", res.data.data[0])
//         setNewArrivalOne(res.data.data[0].One[0])
//         setNewArrivalTwo(res.data.data[0].Two[0])
//         setNewArrivalThree(res.data.data[0].Three[0])
//         setNewArrivalFour(res.data.data[0].Four[0])
//       })
//       .catch((err) => {
//         console.log("err====", err)
//       })
//   }

  
//   useEffect(() => {
//     axios
//       .get(`${Env.server}/api/subsubcatagory/GetAllSubSubCatagory`)
//       .then((res) => {
//         console.log("Abc=====", res.data.subsubcatagories);
        
//         console.log("Abc=====", res.data.subsubcatagories);
        

//         setGetAllModelsLanding(res.data.subsubcatagories); 
        
        
//         const SubCatagoryArray = res.data.subsubcatagories.map(
//           (data) => data.subcatagory
//         );
//         const uniqueOptionsSet1 = new Set(SubCatagoryArray);
//         const uniqueOptionsArray1 = Array.from(uniqueOptionsSet1);
//         setOptions(uniqueOptionsArray1);

      

//         const SubCatagoryArray1 = res.data.subsubcatagories.map(
//           (data) => data.catagory
//         );
//         const uniqueOptionsSet11 = new Set(SubCatagoryArray1);
//         const uniqueOptionsArray11 = Array.from(uniqueOptionsSet11);
//         setOptions1(uniqueOptionsArray11);



//       })
//       .catch((err) => {
//         console.log("err====", err);
//       });
//   }, []);

//   const uniqueSubcategories = new Set(
//     GetAllModelsLanding.map((card) => card.subcatagory)
//   );

  
//   const uniqueSubcategoriesArray = [...uniqueSubcategories];

//   const uniqueSubcategoriesBrand = new Set(
//     GetAllModelsLanding.map((card) => card.catagory)
//   );

  
//   const uniqueSubcategoriesArrayBrand = [...uniqueSubcategoriesBrand];

//   const handleInputChange = (event) => {
//     setSearchText(event?.target?.value?.toLowerCase());
//     setAnchorEl(event.currentTarget);
//   };

//   const handleClick = (event) => {
//     setAnchorEl(anchorEl ? null : event.currentTarget);
//   };

//   const truncateTitle = (title, maxLength) => {
//     const words = title.split(" ");
//     if (words.length > maxLength) {
//       return words.slice(0, maxLength).join(" ") + " ...";
//     }
//     return title;
//   };

//   const toggleSidebar = () => {
//     setSidebarOpen(!sidebarOpen);
//   };


  
//   const selectCategory = (category) => {
//     setSelectedCategory(selectedCategory === category ? null : category);
//   };

//   const mainContainerStyle = {
//     backgroundImage: "linear-gradient(to right, #F1DAFF, #EFBDF3)",
   
//   };

//   const [sublistContent, setSublistContent] = useState(null);
//   const [isHovered, setIsHovered] = useState(false);










//   const handleMouseEnter = (option) => {
//     let TypeData = GetAllModelsLanding.filter(
//       (item) => item.typeName === option
//     ); 
  
//     let categories = {}; 
  
    
//     TypeData.forEach((item) => {
//       if (!categories[item.catagory]) {
//         categories[item.catagory] = [item.subcatagory];
//       } else {
//         categories[item.catagory].push(item.subcatagory);
//       }
//     });
  
  
//     let sublistContent = Object.entries(categories).map(([category, subcategories]) => (
//       <Col key={category} md={4} sm={4} lg={4} xl={4} xs={4} style={{ textAlign: "initial", marginTop: '8px' }}>
//         <h5 style={{ fontWeight: "bold", fontSize: "13px", marginBottom: '0px', cursor:"pointer" }} className="sublist-headings" onClick={() => navigate(`/AmPage4/${option}/${category}`)}>{category}</h5>
//         {subcategories.slice(0,5).map((subcategory, index) => (
//           <li key={index} style={{ listStyle: "none", fontSize: "11px" , cursor:"pointer" }} className="sublist-sub-headings" onClick={() => navigate(`/AccessoryType/${subcategory}/${option}`)}>{subcategory}</li>
//         ))}
//       </Col>
//     ));
  
//     setSublistContent(
//       <Container>
//         <Row className="sublist-container">
//           {sublistContent}
//         </Row>
//       </Container>
//     );
  
//     setIsHovered(true); 
//   };
  
//   const handleMouseLeave = () => {
//     setIsHovered(false); 
//   };



//   const products = [
//     { name: 'Phone Cases', image: 'https://ik.imagekit.io/vhfsx9xkeh/image%2034.png?updatedAt=1713289738287' },
//     { name: 'Charging', image: 'https://ik.imagekit.io/vhfsx9xkeh/275c2254e72a55d3650d06524867998b.png?updatedAt=1712251238630' },
//     { name: 'Tablet Cases', image: 'https://ik.imagekit.io/vhfsx9xkeh/image%2037.png?updatedAt=1713291012814' },
//     { name: 'Screen Protectors', image: 'https://ik.imagekit.io/vhfsx9xkeh/image%2036.png?updatedAt=1713291056736' },
//     { name: 'Screen Protection', image: 'product2.jpg' },
//     { name: 'Cables', image: 'product2.jpg' },
//     { name: 'Speakers', image: 'product2.jpg' },
//     { name: 'Headphones', image: 'product2.jpg' },
//     { name: 'Gaming Accessories', image: 'product2.jpg' },
//   ];


//   const [startIndex, setStartIndex] = useState(0);

//   const handleNext = () => {
//     setStartIndex(startIndex + 4 < products.length ? startIndex + 4 : startIndex);
//   };

//   const handlePrev = () => {
//     setStartIndex(startIndex - 4 >= 0 ? startIndex - 4 : 0);
//   };
  

//   return (
//     <>
//       <Navbar1 />
    



// <h3 style={{ textAlign: "center", marginTop: "40px", fontFamily: 'cursive' }}>All Types</h3>
// <div style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
//   <input placeholder="Search" style={{ width: "300px", height: "30px" }} />
// </div>
// <Container className="mt-4">
//         <Row xs={1} sm={2} lg={4}>
//           {NavbarData?.slice(0, 13).map((item, index) => (
//             <Col key={index} className="mb-3">
//               <Link to={`/AmPage3/${item.type}`} style={{ textDecoration: 'none', color: 'inherit' }}>
//                 <div className="card" style={{ backgroundColor: '#F8F6ED' }}>
//                   <div className="card-body text-center">
//                     <h5 className="card-title">{item.type}</h5>
//                   </div>
//                 </div>
//               </Link>
//             </Col>
//           ))}
//         </Row>
//       </Container>

     

     

    



      











//     </>
//   );
// };
// export default AllTypes;
