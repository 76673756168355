import React, { useState } from "react";
import "../TradeSignup/TradeSignin.css";
import CustomNavbar from "../Navbar";
import CustomFooter from "../../Footer/Footer";
import { Link } from "react-router-dom";
import axios from "axios";
import Env from "../../Environment/Env";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify'; // Added import for react-toastify
import 'react-toastify/dist/ReactToastify.css'; // Added import for react-toastify CSS

const TradeSignin = () => {
  const navigate = useNavigate();
  const [EmailforgotPass, setEmailforgotPass] = useState("");
  const [formData, setFormData] = useState({
    email: "",
    password: ""
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();



 // Validation: Check if all fields are filled
 if (!formData.email || !formData.password) {
  toast.error('All fields are required!', {
    position: 'top-center', // Updated position
});
 return;
}


    // Add your form submission logic here
    console.log(formData); 
      console.log("abc====", formData)
      let link; 
          link = `${Env.server}/api/trader/login`;
    
          axios.post(link, formData)
          .then((res) => {
              const resp = res.data.trader;
              console.log("response===>>>", res.data)
              localStorage.setItem("id", resp._id)
              localStorage.setItem("firstname", resp.firstName)
              localStorage.setItem("lastname", resp.lastName) 
              localStorage.setItem("email", resp.email)
              localStorage.setItem("role", resp.role)
              toast.success('Login successful!', {
                position: 'top-center', // Updated position
            });
              navigate("/"); 
          })
          .catch((err) => {
              console.log("abc===>>>", err);
              toast.error('Login failed. Please check your credentials.', {
                position: 'top-center', // Updated position
            });
          }) 
 
  };

  const forgotFun=()=>{
    axios.get(`${Env.server}/api/user/forgotPassword/${EmailforgotPass}` )
    .then((res)=>{
        console.log("Abc=====", res.data);
        window.open("https://mail.google.com/", "_blank");
    })
    .catch((err) => {
        toast.error(err.response.data.message,{
          position:'top-center'
        });
        console.log("err", err)
    })
}


  return (
    <>
      <CustomNavbar />
      <div className="trade-signin-container">
        <div className="trade-signin-form-container">
          <form onSubmit={handleSubmit} className="trade-signin-form">
          <h1 className="fs-7" style={{color: '#071A44'}}>Sign In</h1>
            <div className="trade-form-group">
              <input
                type="email"
                name="email"
                className="trade-signin-input"
                value={formData.email}
                onChange={handleChange}
                placeholder="Email"
                required
              />
              <input
                type="password"
                name="password"
                className="trade-signin-input"
                value={formData.password}
                onChange={handleChange}
                placeholder="Password"
                required
              />
            </div>
            <div class="form-check pb-2">
                            <label className="delete-icon"
                                data-toggle="modal"
                                data-target="#exampleModalLong1" class="form-check-label"  style={{color: '#071A44' , cursor:"pointer"}} >
                                Forgot your password?
                            </label>
                        </div>
            <button type="button" onClick={handleSubmit} class="btnSubmit btn-lg mt-2 bg-white fw-bolder fs-9" style={{color: '#071A44', boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)'}}>Sign In</button>
            <p className="text-center">
              Don't have an account? <Link to="/TradeSignup">Sign up</Link>
            </p>
          </form>
        </div>
      </div>
      <CustomFooter />
      <div class="modal fade" id="exampleModalLong1" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLongTitle">Email</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                        <input type="email" onChange={(e)=>setEmailforgotPass(e.target.value)} class="form-control" placeholder="Email Address"/>
                        </div>

                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button type="button"
                                class="btn btn-primary"
                                data-dismiss="modal"
                                onClick={forgotFun}
                                >Confirm</button>
                        </div>
                    </div>
                </div>
            </div>

      <ToastContainer /> {/* Added ToastContainer for toast notifications */}
    </>
  );
};

export default TradeSignin;
