import React, { useEffect , useState } from "react";
import './slidercontetnNVADIA.css';
import Navbar from "../Navbar/Navbar";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Env from "../Environment/Env"
import { getAsset } from "../utils/helper";


const SlidercontentnNVADI=()=>{
    let navigate = useNavigate();
    const {id} = useParams();
 
    console.log("abc=======", id);
    const [SingleproductData , setSingleProductData] = useState({});
    const [ClickId, setClickId] = useState("");
    const [QuantityFinal, setQuantityFinal] = useState(1); // State variable to hold quantity


    useEffect(()=>{
        GetProductData(); 
    },[])

    const GetProductData=()=>{
        axios.get(`${Env.server}/api/product/getSingleProductbyid/${id}`)
        .then((res) => {
            console.log("res====>>>", res.data.products);
            setSingleProductData(res.data.products)
        })
        .catch((err) => {
            console.log("err====>>>", err);
        })
    }


    const handleSellProduct = (data, id, quan) => {
        console.log("quanquanquan....", quan)
        if (quan === 0 || quan === undefined) {
            quan = 1
        }
        console.log("quanquanquan....", quan)

        const existingData = JSON.parse(localStorage.getItem('productData')) || []; // Step 1: Retrieve existing data or initialize as an empty array
        const newData = { data: data, quantity: quan }; // Create new data object

        // Find if the product already exists in the existing data
        const existingIndex = existingData.findIndex(item => item.data._id === id);

        if (existingIndex !== -1) {
            // If product exists, update its quantity
            existingData[existingIndex].quantity = quan;
        } else {
            // If product doesn't exist, add it to the data array
            existingData.push(newData);
        }

        // Step 3: Save the updated data back to the localStorage
        localStorage.setItem('productData', JSON.stringify(existingData));

        setQuantityFinal(quan);
        setClickId(id);

        console.log("abcmuzamal======", data);
    }



    return(
        <>
        <Navbar/><br/><br/><br/>
       <div   class="ali">
       <div style={{background:"#FFFFFF"}}>
        <div class="container">
            
            <div class=" row upper-section text-white py-5 d-flex justify-content-between p-4 ">
                {/* <!-- Just Mobile Pic --> */}
                <div class=" col-md-4 mobile-pic p-5 mt-5 ">
                                <img style={{height:"300px"}} src={getAsset(SingleproductData.image && SingleproductData.image[0])} width="250px"/>
                </div>
                {/* <!-- Specifications --> */}
                <div class=" col-md-6 mobile-specs mt-5 me-5">
                    <p class="small" style={{color:"#071A44"}}> </p>
                                <h2 style={{ color: "#071A44" }}>{SingleproductData.name}</h2> 
                    <p class="my-3 fw-bold" style={{color: "#071A44"}}>More Details:</p>
                    <div class="cash d-flex justify-content-between" style={{color:"#071A44"}}>
                        <p class="leads">Cash</p>
                                    <p class="leads">£{SingleproductData.price}</p>
                    </div>
                                
                   
                </div>


                         
                  





        </div>
            <hr style={{color:"#071A44"}}/>

            <div class="color" style={{color:"#071A44"}}>

                <div class="product-detail-sepcification">
                    <h4 class="my-5 fw-bold leads">Product Details</h4>
                    <h4 class="specs">Specification</h4>
                    <ul class="mt-3">
                                    <li> <span class="fw-bold">Model Name: &nbsp;:</span> &nbsp; {SingleproductData.options && SingleproductData.options.ModelName && SingleproductData.options.ModelName[0]}</li>
                                    <li> <span class="fw-bold">Edition: &nbsp;:</span> &nbsp; {SingleproductData.options && SingleproductData.options.Edition && SingleproductData.options.Edition[0]}</li>
                                    <li> <span class="fw-bold">Storage: &nbsp;:</span>  &nbsp; {SingleproductData.options && SingleproductData.options.Storage && SingleproductData.options.Storage[0]}</li>
                                    <li> <span class="fw-bold">Condition: &nbsp;:</span> &nbsp; {SingleproductData.options && SingleproductData.options.Condition && SingleproductData.options.Condition[0]}  </li>
                    </ul>
                </div>

                            <button style={{ marginLeft: "40px" }} onClick={() => {
                                handleSellProduct(SingleproductData, SingleproductData._id, 1);
                                navigate("/Cart")
                            }} className="btn btn-primary">&nbsp;&nbsp;Sell Now&nbsp;&nbsp;</button>


                {/* <div class="product-desc">
                    <h4 class="my-5">Product Description</h4>
                    <h6 class="fw-bold">Ray Tracing</h6>
                    <p class="leads">The Ada architecture unleashes the full glory of ray tracing, which simulates how light behaves in the real world. With the power of RTX 40 Series and third-gen RT Cores, you can experience incredibly detailed virtual worlds.</p>

                    <h6 class="fw-bold">The performance multiplier, powered by AI</h6>
                    <p class="leads">DLSS is a revolutionary breakthrough in AI-powered graphics that boosts performance. Powered by the fourth-gen Tensor Cores and Optical Flow Accelerator on GeForce RTX 40 Series GPUs, DLSS 3 uses AI to create high-quality frames.</p>

                    <h6 class="fw-bold">Victory measured in milliseconds</h6>
                    <p class="leads">NVIDIA Reflex and GeForce RTX 40 Series GPUs deliver the lower latency and responsiveness for the ultimate competitive advantage. Built to optimize and measure system latency, Reflex provides fast target acquisition, quicker reaction times, and the best aim precision for competitive game</p>

                    <h6 class="fw-bold">Game Ready Drivers</h6>
                    <p class="leads">GeForce Game Ready Drivers deliver the better experience for your favorite games. They're finely tuned in collaboration with developers and extensively tested across thousands of hardware configurations for better performance and reliability.</p>

                    <h6 class="fw-bold">NVIDIA G-SYNC</h6>
                    <p class="leads">Get smooth, tear-free gameplay at high refresh rates, plus HDR and more. This is the ultimate gaming display and the go-to equipment for enthusiast gamer</p>

                    <h6 class="fw-bold">8K HDR Gaming</h6>
                    <p class="leads">Connect, play, capture, and watch in brilliant HDR at resolutions up to 8K with the GeForce RTX 4090. Capture up to 8K HDR footage with the GeForce Experience ShadowPlay feature and play back smoothly with AV1 decode.</p>

                    <h6 class="fw-bold">Virtual Reality</h6>
                    <p class="leads">The high performance graphics deliver the immersive VR experiences.</p>

                    <h6 class="fw-bold">Resizable BAR</h6>
                    <p class="leads">Resizable BAR is an advanced PCI Express feature that enables the CPU to access the entire GPU frame buffer at once, improving performance in many gam</p>

                    <h4 class="tech-detail my-5">Technical Details:</h4>
                <h6 class="fw-bold">RAM</h6>
                <li class="leads">24GB</li>
                <li class="leads">GDDR6X</li>
                <li class="leads">384-bit memory interface</li>
                <li class="leads">2.23 GHz base clock</li>

                <h6 class="fw-bold mt-4">General</h6>
                <li class="leads">Microsoft DirectX 12 Ultimate support</li>
                <li class="leads">16384 CUDA Cores</li>
                <li class="leads">OpenGL 4.6</li>
                <li class="leads">2.52 GHz boost clock</li>
                <li class="leads">HDMI, 3 x DisplayPort 1.4a</li>
                <li class="leads">HDCP 2.3</li>
                <li class="leads">Maximum digital resolution 7680 x 4320</li>
                <li class="leads">4 multi display</li>
                <li class="leads">PCI Express Gen 4.0 bus interface</li>
                <li class="leads">304 x 137 mm</li>
                <li class="leads">3-Slot (61mm)</li>
                
                </div> */}

            </div>
        </div>
        <br/><br/>
   </div>
    </div>
        </>
    )
}
export default SlidercontentnNVADI;