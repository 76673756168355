import React from 'react';
import Navbar from '../Navbar/Navbar';
import  { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Env from "../Environment/Env";
import { getAsset } from "../utils/helper";
import {Paper, Grid, Card, CardContent} from '@mui/material'

const Header = () => {

    const [searchData, setSearchData] = useState({});
    const [SubCatagoryData, setSubCatagoryData] = useState({});
    const [GetData, setGetData] = useState([])
    let check = localStorage.getItem("role");
    
    
    useEffect(()=>{
        getAllSubCatagories();
    },[]);
    
    
    const AllGetSearchData = (data) => {
        axios.get(`${Env.server}/api/product/getSingleProduct/${data}`)
            .then((res) => {
                // console.log("abc===>>>", res.data.message);
                console.log("abc===>>>", res.data.products);
                setSearchData(res.data.products)
            })
            .catch((err) => {
                console.log("err===>>>", err);
            })
    }
    
    const getAllSubCatagories = ()=>{
        axios.get(`${Env.server}/api/subcatagory/GetAllSubCatagory`)
    .then((res)=>{
        console.log("res===>>>", res.data.subcatagories);
        setSubCatagoryData(res.data.subcatagories)
    })
    .catch((err) => {
        console.log("err===>>>", err);
    })
    }
    
    return ( 
    <>
    
    
            <Navbar />      


        <div className='row justify-content-center'>
        <div className="col-md-12 text-center pt-2">

<div className="main d-flex align-items-center mt-5 pb-4">
    <div className="card m-3">
        <div className="card-body bg-white">
            <h2 className="card-title ">Find accessories for your device</h2>
            <p className="card-text">Enter phone, tablet or wearable</p>
            <input type="text" className="form-control mb-3" onChange={(e) => AllGetSearchData(e.target.value)} placeholder="Search" />
            <p className="card-text"><a href="#" className="text-decoration-none text-primary">Can't see your device?</a></p>
            <p className="card-text"><a href="#" className="text-decoration-none text-primary">Need help identifying your device?</a></p>
        </div>
    </div>
                            
</div>

</div>
        </div>
    
    </>

  );
};

export default Header
