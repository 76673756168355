import React , {useState , useEffect} from "react";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import Navbar1 from "../Navbar/Navbar1"
import axios from "axios";
import { loadStripe } from "@stripe/stripe-js";
import Env from '../Environment/Env'
import { PubKey } from "../PubKey/PubKey";
import { getAsset } from "../utils/helper";
import { Link } from "react-router-dom";
import { MdCancel } from 'react-icons/md'; // Import the React Icons
import Footer from "../Footer/Footer";
import { useDispatch, useSelector } from "react-redux";

const CartPage = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const [ClickData, setClickData] = useState([]);
  const [productData, setProductData] = useState([]); // State hook to hold product data from localStorage
  const [TotalPrice, setTotalPrice] = useState(0); // State hook to hold product data from localStorage
  const [Message, setMessage] = useState(""); // State hook to hold product data from localStorage
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectedOption, setSelectedOption] = useState('regular');
  const cartItems = useSelector(state=>state.cart.items);

  useEffect(() => {
    console.log('aaaaaaaaaaaaaa',cartItems);
    getAllUpdate();
  }, [])

const getAllUpdate = () => {
  const storedProducts = JSON.parse(localStorage.getItem('selectedProducts')) || [];
  setSelectedProducts(storedProducts);
  const parsedData = storedProducts;
  console.log("Abcd====", parsedData);
}

  const removeItem = (id) => {
    const updatedProductData = productData.filter(item => item.data._id !== id);
    setProductData(updatedProductData);
    localStorage.setItem("productData", JSON.stringify(updatedProductData));
  };


  const SubmitOrder = async () => {

    const stripe = await loadStripe(PubKey);
    console.log("abc====", selectedProducts)
    let deliveryCharge = 0;

    if (selectedOption === "regular") {
      if (TotalPrice >= 15) {
        deliveryCharge = 0
      } else {
        deliveryCharge = 399
      }
    } else if (selectedOption === "express") {
      if (TotalPrice >= 15) {
        deliveryCharge = 0
      } else {
        deliveryCharge = 599
      }
    }


    console.log("deliverycharges:====", deliveryCharge)

    axios
      .post(`${Env.server}/api/customer/order-online`, { data: selectedProducts, totalPrice: TotalPrice, deliverycharges: deliveryCharge })
      .then((res) => {
        console.log("muzzamal", res.data);
        const result = stripe.redirectToCheckout({
          sessionId: res.data.sessionId,
        });
        if (result.error) throw new Error("Stripe Payment Not Successful!");
      })
      .catch((err) => console.log(err));
  }

  React.useEffect(() => {
    getDataByIDLocalStorage();
  }, [])

  // Function to retrieve data by ID from localStorage
  const getDataByIDLocalStorage = () => {
    let data = localStorage.getItem("selectedProducts");
    const parsedData = JSON.parse(data);
    console.log("Abcd====", JSON.parse(data));
    if (data) {
      setProductData(JSON.parse(data)); // Parse the data and update the state

      let totalPrice = parsedData.reduce((total, item) => {
        console.log("hhhh", item)

        return total + (item.productPrice * item.quantity);
      }, 0);

      console.log("Total Price:", totalPrice);
      setTotalPrice(totalPrice)
    }
  }


  
  const [productQuantity, setProductQuantity] = useState({});

  // Function to handle incrementing quantity
  const incrementQuantity = (card) => {
    let productId = card._id;
    // selectedProducts
    let selectedProducts = JSON.parse(localStorage.getItem('selectedProducts')) || [];

    console.log("productQuantity====", card.quantity + 1);
    console.log("productQuantity====", card.quantity + 1);
    
    for (let i = 0; i < selectedProducts.length; i++) {
      if (selectedProducts[i]._id === productId) {
          // Update quantity of the matching product
          selectedProducts[i].quantity = card.quantity + 1;
          break; // Exit loop once product is found and updated
      }
  }

  // Save updated selectedProducts array back to localStorage
  localStorage.setItem('selectedProducts', JSON.stringify(selectedProducts));
getAllUpdate()

};

// Function to handle decrementing quantity
const decrementQuantity = (card) => {
  let productId = card._id;
  // selectedProducts
  let selectedProducts = JSON.parse(localStorage.getItem('selectedProducts')) || [];

  console.log("productQuantity====", card.quantity + 1);
  console.log("productQuantity====", card.quantity + 1);
  
  for (let i = 0; i < selectedProducts.length; i++) {
    if (selectedProducts[i]._id === productId) {
        // Update quantity of the matching product
        if(selectedProducts[i].quantity === 0){
          console.log("jjj", selectedProducts[i].quantity)
          selectedProducts[i].quantity = 1;
          break; // Exit loop once product is found and updated
        }else{
          console.log("jjj", selectedProducts[i].quantity)
          if(card.quantity > 1){
            selectedProducts[i].quantity = card.quantity - 1;
          }
        }
    }
}

// Save updated selectedProducts array back to localStorage
localStorage.setItem('selectedProducts', JSON.stringify(selectedProducts));
getAllUpdate()
};




  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    console.log(event.target.value);
  };

  const handleSubmit = () => {
    let login = localStorage.getItem("role");
    if (login) {
      console.log("abc=====>>>>", productData);
      let id = localStorage.getItem("id");
      let role = localStorage.getItem("role");
      let orderplaceData = {
        clientid: id,
        role: "forsale",
        name: localStorage.getItem("firstname") + " " + localStorage.getItem("lastname"),
        product: productData,
        totalprice: TotalPrice
      }
      console.log("abcbbecbeb======", orderplaceData);

      axios.post(`${Env.server}/api/order/neworder`, orderplaceData)
        .then((res) => {
          console.log("abc===>>>", res.data)
          console.log("abc===>>>", res.data.message)
          if (res.data.message === "success") {
            localStorage.removeItem("productData");
            setProductData([]);
            setTotalPrice(0);
            setMessage("Product Sell request sent Successfully. Please Check your email");
            const timer = setTimeout(() => {
              setMessage("");
              navigate("/");
            }, 5000);
            return () => clearTimeout(timer);
          }
        })
        .catch((err) => {
          console.log("err======", err);
        })

    } else {
      alert("Please Login First")
    }

  }

  const handleDelete = (index) => {
    // Create a copy of selectedProducts array
    const updatedProducts = [...selectedProducts];
    // Remove the product at the specified index
    updatedProducts.splice(index, 1);
    // Update the state with the modified array
    setSelectedProducts(updatedProducts);
    // Update the localStorage with the modified array
    localStorage.setItem('selectedProducts', JSON.stringify(updatedProducts));
    window.location.reload();
  };







  const handleQuantityChange = (index, amount) => {
    // Create a copy of selectedProducts array
    const updatedProducts = [...selectedProducts];
    // Update the quantity of the product at the specified index
    updatedProducts[index].quantity += amount;
    // If quantity becomes 0, remove the product
    if (updatedProducts[index].quantity === 0) {
      updatedProducts.splice(index, 1);
    }
    // Update the state with the modified array
    setSelectedProducts(updatedProducts);
    // Update the localStorage with the modified array
    localStorage.setItem('selectedProducts', JSON.stringify(updatedProducts));
  };

  const handleRemoveProduct = (index) => {
    const updatedSelectedProducts = [...selectedProducts];
    updatedSelectedProducts.splice(index, 1);
    setSelectedProducts(updatedSelectedProducts);
    // Optionally, update localStorage if needed
    localStorage.setItem('selectedProducts', JSON.stringify(updatedSelectedProducts));
};



  return (
    <>
      <Navbar1 />
      <Container style={{ marginTop: "50px",marginBottom: "50px" , paddingLeft: "50px", paddingRight: "50px" }}>
        <h2 className="ml-5">Cart</h2>
        <Row>
          {/* First Section */}
          <Col lg={8}>

            {selectedProducts.map((card, index) => (
              <>

              
            <Card
            key={index}
              className="mb-3"
              style={{ backgroundColor: "#F9FAFB", maxWidth: "1000px", border: '1px solid #000000' }}
            >
              <Card.Body className="d-flex align-items-center justify-content-between pr-3">
                <div className="d-flex align-items-center">
                  <img 
                    src={getAsset(card && card.imageSubsubCatagory && card.imageSubsubCatagory[0])}
                    alt="Product"
                    className="mr-3"
                    style={{
                      maxWidth: "100px",
                      maxHeight: "100px",
                      width: "auto",
                      height: "auto",
                    }}
                  />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                    }}
                  >
                    <h6 style={{ marginLeft: "5px" }}>
                          {card.subsubcatagory}
                    </h6>
                    <button
                      style={{
                        color: "#DB241B",
                        textDecoration: "underline",
                        background: 'none',
                        fontSize: "15px",
                        border: "none",
                      }}
                          onClick={() => handleDelete(index)}
                    >
                      Remove
                    </button>
                  </div>
                </div>
                <div
                  style={{
                    // border: "1px solid #D0D1D8",
                    // backgroundColor: "white",
                    maxWidth: "800px",
                  }}
                >
                  <div
                    className="d-flex align-items-center justify-content-between"
                    style={{}}
                  >

                    {/* <Button variant="" size="sm" className="mr-2">
                      -
                    </Button> */}
                        {/* <span>Quantity: {card.quantity}</span> */}
                        <div  style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <button style={{border: 'none', background: 'none', width: '15px', fontWeight: 'bold'}} onClick={() => decrementQuantity(card)}>-</button>
            <span>&nbsp;&nbsp;{card.quantity || 1}&nbsp;&nbsp;</span>
            <button style={{border: 'none', background: 'none', width: '15px', fontWeight: 'bold'}} onClick={() => incrementQuantity(card)}>+</button>
 
        </div>
 
 
        </div>  
                </div> 
                {
                  card.selectedColor && 
                  <div className="">
                        <h5>{card.selectedColor?.name ||card.selectedColor }</h5>
                  </div>
                }
                <div className="">
                      <h5>£{card.productPrice} / unit</h5>
                </div>
              </Card.Body>
            </Card>
              </>
            ))}
            {/* Repeat this Card for other products */}
            <h6 >
              <Link to="/">
                <span style={{ cursor: "pointer" }} >
                + Add another product
                </span>
              </Link>
            </h6>
          </Col>

          {/* Second Section */}
          
          
          <div className="col-4 " style={{ padding: "0px",marginTop: '0px' }}>
          <div style={{borderRadius: '20px', paddingTop: '10px',  paddingRight: '10px',fontSize: '25px', paddingLeft: "10px", border: "2px solid lightgray",backgroundColor: '#F8F6ED'}}>
            <h5 className="text-center" style={{color: '#000000', fontWeight: 'bold', marginBottom: '7px'}}>Your order summary</h5>
            <div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
    <p className="col-3" style={{ fontWeight: '600', marginBottom: '0', fontSize: '17px' }}>Product</p>
    <p className="col-2" style={{ fontWeight: '500', marginBottom: '0', fontSize: '17px' , fontWeight:"bolder" }}>Price</p>
    <p className="col-2" style={{ fontWeight: '500', marginBottom: '0', fontSize: '17px' , fontWeight:"bolder" }}>Qty</p>
    <p className="col-3" style={{ fontWeight: '500', marginBottom: '0', fontSize: '17px' , fontWeight:"bolder" }}>T.Price</p>
    <p className="col-2 hidden-element" style={{ fontWeight: '500', marginBottom: '0', fontSize: '7px' , fontWeight:"bolder", visibility: 'hidden' }}>Price</p> 

  </div>
  {selectedProducts.map((item, index) => (
    <>
        <div key={index} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <p className="col-3" style={{ fontWeight: '600', marginBottom: '0', fontSize: '13px' }}>
            {item?.subsubcatagory}
            {item.selectedColor && <p>{`(${item.selectedColor.name || item.selectedColor })`}</p>}
          </p>
          <p className="col-2" style={{ fontWeight: '500', marginBottom: '0', fontSize: '13px' }}>£{item.productPrice}</p>
          <p className="col-2" style={{ fontWeight: '600', marginBottom: '0', fontSize: '13px' }}>{item?.quantity}</p>
          <p className="col-3" style={{ fontWeight: '500', marginBottom: '0', fontSize: '13px' }}>£{(item.productPrice * item?.quantity).toFixed(2)}</p>
          <MdCancel className="col-2" onClick={() => handleRemoveProduct(index)} style={{fontSize: '20px', color: 'red', cursor: 'pointer',  margin: 'auto' }} />
        </div>
        <hr/>
    </>
      ))}

                <br/> 
                
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <p style={{ fontWeight: '600', marginBottom: '0', fontSize: '13px' }}>Total Price</p>
        <p style={{ fontWeight: '500', marginBottom: '0', fontSize: '13px' }}>
          £{selectedProducts?.reduce((total, item) => total + parseFloat(item?.productPrice * item?.quantity), 0).toFixed(2)}
        </p>
      </div>
        <br/>
                
                <Link to="/Cart2">
                <button
                    style={{
                      color: "black",
                      borderRadius: '50px',
                      backgroundColor: '#F8D803',
                      fontSize: "10px", 
                      padding: '10px 10px 10px 10px',
                      width: '70%',
                      marginTop: '5px',
                      border: 'none',
                      fontWeight: 'bold',
                      display: 'flex',
                      justifyContent: 'center',
                      margin: 'auto',
                      marginBottom: '8px',
                      alignItems: 'center'
                      
                    }}
                  >
                    Continue to checkout
                </button>
                </Link>
                
             
            </div>
          </div>
            
          </div>  

        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default CartPage;
