// EditableCard.js

import React, { useState } from "react";
import { Link } from "react-router-dom";

const Useraddressinfo = () => {
  const [email, setEmail] = useState("shahananwar39@gmail.com");
  const [newEmail, setNewEmail] = useState("");
  const [confirmEmail, setConfirmEmail] = useState("");
  const [isEditing, setIsEditing] = useState(false);



  const [displayDiv, setDisplayDiv] = useState(1); // State to track which div to display

  const handleAddAddressClick = () => {
    setDisplayDiv(2);
  };

  const handleCannotFindAddressClick = () => {
    setDisplayDiv(3);
  };

  const handleAddAddressThirdDivClick = () => {
    setDisplayDiv(4);
  };



  const [formData, setFormData] = useState({
    streetAddress: "",
    city: "",
    state: "",
    postalCode: "",
    country: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here
    console.log(formData);
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSaveClick = () => {
    setIsEditing(false);
    // Implement logic to save changes (e.g., update state, make API calls)

    // For simplicity, just update the email with the new email
    setEmail(newEmail);
  };

  return (
    <>
      <div
        style={{
          position: "relative",
          width: "100%",
          maxWidth: "720px",
          padding: "20px",
          borderRadius: "16px",
          margin: "20px auto",
          backgroundColor: "white",
          border: "1px solid #ccc",
        }}
      >
        <div style={{ marginBottom: "20px" }}>
          <h3>Address Info</h3>
        </div>

        <div style={{ marginBottom: "20px" }}>
          {/* <label style={{ marginBottom: '5px' }}>Email</label> */}
          <div
            style={{
              padding: "8px",
              borderRadius: "5px",
              backgroundColor: "white",
            }}
          >
            This is your current saved address
          </div>
          <Link to="/">
            <h6 style={{ cursor: "pointer" }}>+ Add Address</h6>
          </Link>
        </div>
      </div>

      <div style={{ display: "flex", justifyContent: "center" }}>
        <div
          style={{
            width: "100%",
            maxWidth: "720px",
            padding: "20px",
            borderRadius: "16px",
            margin: "20px auto",
            backgroundColor: "white",
            border: "1px solid #ccc",
          }}
        >
          <div style={{ marginBottom: "20px" }}>
            <ul>
              <li>This is your current saved address</li>
            </ul>
          </div>
          <div
            style={{
              position: "relative",
              display: "flex",
              alignItems: "center",
            }}
          >
            <input
              type="text"
              placeholder="Search..."
              style={{
                width: "100%",
                padding: "10px 40px 10px 10px",
                borderRadius: "8px",
                border: "1px solid #ccc",
              }}
            />
            <div
              style={{
                position: "absolute",
                right: "90px",
                top: "50%",
                transform: "translateY(-50%)",
              }}
            >
              <i className="fa fa-search"></i>
            </div>
            <button
              style={{
                marginLeft: "10px",
                padding: "10px 20px",
                border: "1px solid #DEE2E6",
                borderRadius: "8px",
                backgroundColor: "#F8F9FA",
                color: "black",
                cursor: "pointer",
              }}
            >
              Add
            </button>
          </div>
          <Link to="/">
            <h6 style={{ cursor: "pointer", padding: "5px" }}>
              Cannot Find Address
            </h6>
          </Link>
        </div>
      </div>

      <div
        style={{
          position: "relative",
          width: "100%",
          maxWidth: "720px",
          padding: "20px",
          borderRadius: "16px",
          margin: "20px auto",
          backgroundColor: "white",
          border: "1px solid #ccc",
        }}
      >
        <div style={{ marginBottom: "20px" }}>
          <div style={{ marginBottom: "20px" }}>
            <ul>
              <li>Unable to find our address</li>
            </ul>
          </div>
          <Link to="/">
            <h6 style={{ cursor: "pointer" }}>+ Add Address</h6>
          </Link>
        </div>
      </div>

      <div
        style={{
          position: "relative",
          width: "100%",
          maxWidth: "720px",
          padding: "20px",
          borderRadius: "16px",
          margin: "20px auto",
          backgroundColor: "white",
          border: "1px solid #ccc",
        }}
      >
        <form
          onSubmit={handleSubmit}
          style={{ display: "flex", flexDirection: "column" }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginBottom: "20px",
            }}
          >
            <div style={{ flex: "1", marginRight: "10px" }}>
              <label htmlFor="streetAddress">Street Address:</label>
              <input
                type="text"
                id="streetAddress"
                name="streetAddress"
                value={formData.streetAddress}
                onChange={handleChange}
                required
                style={{
                  width: "100%",
                  padding: "8px",
                  border: "1px solid #ccc",
                  borderRadius: "5px",
                  boxSizing: "border-box",
                }}
              />
            </div>
            <div style={{ flex: "1", marginRight: "10px" }}>
              <label htmlFor="city">City:</label>
              <input
                type="text"
                id="city"
                name="city"
                value={formData.city}
                onChange={handleChange}
                required
                style={{
                  width: "100%",
                  padding: "8px",
                  border: "1px solid #ccc",
                  borderRadius: "5px",
                  boxSizing: "border-box",
                }}
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginBottom: "20px",
            }}
          >
            <div style={{ flex: "1", marginRight: "10px" }}>
              <label htmlFor="state">State:</label>
              <input
                type="text"
                id="state"
                name="state"
                value={formData.state}
                onChange={handleChange}
                required
                style={{
                  width: "100%",
                  padding: "8px",
                  border: "1px solid #ccc",
                  borderRadius: "5px",
                  boxSizing: "border-box",
                }}
              />
            </div>
            <div style={{ flex: "1", marginRight: "10px" }}>
              <label htmlFor="postalCode">Postal Code:</label>
              <input
                type="text"
                id="postalCode"
                name="postalCode"
                value={formData.postalCode}
                onChange={handleChange}
                required
                style={{
                  width: "100%",
                  padding: "8px",
                  border: "1px solid #ccc",
                  borderRadius: "5px",
                  boxSizing: "border-box",
                }}
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginBottom: "20px",
            }}
          >
            <div style={{ flex: "1" }}>
              <label htmlFor="country">Country:</label>
              <input
                type="text"
                id="country"
                name="country"
                value={formData.country}
                onChange={handleChange}
                required
                style={{
                  width: "100%",
                  padding: "8px",
                  border: "1px solid #ccc",
                  borderRadius: "5px",
                  boxSizing: "border-box",
                }}
              />
            </div>
          </div>
          <input
            type="submit"
            value="Submit"
            style={{
              fontWeight: "600",
              border: "1px solid #DEE2E6",
              borderRadius: "8px",
              backgroundColor: "#F8F9FA",
              width: "100%",
              maxWidth: "200px",
              padding: "10px",
              color: "black",
              cursor: "pointer",
            }}
          />
        </form>
      </div>
    </>
    
  );
};

export default Useraddressinfo;
