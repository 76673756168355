
// import React, { useState } from 'react';
// import Navbar from "../Navbar/Navbar";
// import Footer from "../Footer/Footer";

// const Contactus = () => {
//   const [formData, setFormData] = useState({
//     name: '',
//     email: '',
//     phone: '',
//     subject: '',
//     message: ''
//   });

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({
//       ...formData,
//       [name]: value
//     });
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     // Handle form submission logic here
//     console.log(formData);
//     // Reset form after submission
//     setFormData({
//       name: '',
//       email: '',
//       phone: '',
//       subject: '',
//       message: ''
//     });
//   };

//   return (
//     <>
//     <Navbar />
//     <div className='' style={{ maxWidth: '500px', margin: '40px auto', padding: '20px', backgroundColor: '#f9f9f9', borderRadius: '5px' }}>
//       <h2>Contact Us</h2>
//       <form onSubmit={handleSubmit}>
//         <div style={{ marginBottom: '15px' }}>
//           <input
//             type="text"
//             name="name"
//             placeholder="Your Name"
//             value={formData.name}
//             onChange={handleChange}
//             required
//             style={{ width: 'calc(50% - 5px)', padding: '10px', fontSize: '16px', borderRadius: '5px', border: '1px solid #ccc' }}
//           />
//           <input
//             type="email"
//             name="email"
//             placeholder="Your Email"
//             value={formData.email}
//             onChange={handleChange}
//             required
//             style={{ width: 'calc(50% - 5px)', padding: '10px', fontSize: '16px', borderRadius: '5px', border: '1px solid #ccc' }}
//           />
//         </div>
//         <input
//           type="tel"
//           name="phone"
//           placeholder="Your Phone"
//           value={formData.phone}
//           onChange={handleChange}
//           required
//           style={{ width: '100%', padding: '10px', fontSize: '16px', borderRadius: '5px', border: '1px solid #ccc', marginBottom: '15px' }}
//         />
//         <input
//           type="text"
//           name="subject"
//           placeholder="Subject"
//           value={formData.subject}
//           onChange={handleChange}
//           required
//           style={{ width: '100%', padding: '10px', fontSize: '16px', borderRadius: '5px', border: '1px solid #ccc', marginBottom: '15px' }}
//         />
//         <textarea
//           name="message"
//           placeholder="Your Message"
//           value={formData.message}
//           onChange={handleChange}
//           rows="4"
//           required
//           style={{ width: '100%', padding: '10px', fontSize: '16px', borderRadius: '5px', border: '1px solid #ccc', marginBottom: '15px' }}
//         />
//         <button
//           type="submit"
//           style={{ width: '100%', padding: '10px', fontSize: '16px', backgroundColor: '#007bff', color: '#fff', border: 'none', borderRadius: '5px', cursor: 'pointer', transition: 'background-color 0.3s ease' }}
//         >
//           Submit
//         </button>
//       </form>
//     </div>
//     <Footer />
//   </>
//   );
// };

// export default Contactus;
import React, { useState } from 'react';
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import Env from '../Environment/Env';

const Contactus = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    subject: '',
    message: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here
    console.log(formData);
    // Reset form after submission
    setFormData({
      name: '',
      email: '',
      phone: '',
      subject: '',
      message: ''
    });
  };

  return (
    <>
      <Navbar />

      <div className='contact-us-container' style={{ display: 'flex', justifyContent: 'space-between', maxWidth: '900px', margin: '40px auto' }}>
        <div className='contact-info' style={{ width: '45%' }}>
          <h2>Need Help? Reach Us</h2>
          <p>
            We are dedicated to providing exceptional support and assistance to our valued customers. Whether you have a question about our products, need technical assistance, or simply want to provide feedback, we're here to help.<br />
            Our team is committed to ensuring your experience with us is seamless and enjoyable. We understand that sometimes questions arise, and we want to make it easy for you to get the answers and support you need.<br />
            Feel free to reach out to us using the contact form provided on this page. Simply fill out the required fields with your name, email, phone number, subject, and message, and we'll get back to you as soon as possible.<br />
            Additionally, you can find our contact information below for your convenience:
          </p>
          <ul>
            <li>Address: {Env.address}</li>
            <li>Phone: {Env.phone}</li>
            <li>Email: {Env.email}</li>
            {/* Add more bullet points as needed */}
          </ul>
        </div>
        <div className='contact-form' style={{ width: '45%' }}> 
          <div style={{ backgroundColor: '#f9f9f9', borderRadius: '5px', padding: '20px' }}>
            <h2>Contact Us</h2>
            <form onSubmit={handleSubmit}>
              <div style={{ marginBottom: '15px' }}>
                <input
                  type="text"
                  name="name"
                  placeholder="Your Name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                  style={{ width: 'calc(50% - 5px)', padding: '10px', fontSize: '16px', borderRadius: '5px', border: '1px solid #ccc', marginRight: '10px' }}
                />
                <input
                  type="email"
                  name="email"
                  placeholder="Your Email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                  style={{ width: 'calc(50% - 5px)', padding: '10px', fontSize: '16px', borderRadius: '5px', border: '1px solid #ccc' }}
                />
              </div>
              <input
                type="tel"
                name="phone"
                placeholder="Your Phone"
                value={formData.phone}
                onChange={handleChange}
                required
                style={{ width: '100%', padding: '10px', fontSize: '16px', borderRadius: '5px', border: '1px solid #ccc', marginBottom: '15px' }}
              />
              <input
                type="text"
                name="subject"
                placeholder="Subject"
                value={formData.subject}
                onChange={handleChange}
                required
                style={{ width: '100%', padding: '10px', fontSize: '16px', borderRadius: '5px', border: '1px solid #ccc', marginBottom: '15px' }}
              />
              <textarea
                name="message"
                placeholder="Your Message"
                value={formData.message}
                onChange={handleChange}
                rows="4"
                required
                style={{ width: '100%', padding: '10px', fontSize: '16px', borderRadius: '5px', border: '1px solid #ccc', marginBottom: '15px' }}
              />
              <button
                type="submit"
                style={{ width: '100%', padding: '10px', fontSize: '16px', backgroundColor: '#007bff', color: '#fff', border: 'none', borderRadius: '5px', cursor: 'pointer', transition: 'background-color 0.3s ease' }}
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Contactus;
