const Env = {
    //   server: "http://localhost:8089",
    //   dashboard: "http://localhost:3001/",  
      tradeSite: "https://trade.repairshub.co.uk",
    server: "https://backend.repairshub.co.uk",  
    dashboard: "https://backoffice.repairshub.co.uk",  
    picLogo:"https://ik.imagekit.io/vhfsx9xkeh/LOGO%20(1)%20(1).png?updatedAt=1715417181642",
   address: " REPAIR HUB , 149 High St, , Poole , BH15 1AS,UK",
    phone: " 01202801200",
    email: "booking@repairshub.co.uk",
    facebook: "https://www.facebook.com/",
    instagram: "https://www.instagram.com/",
    twitter: "https://twitter.com/",
    linkedin: "https://www.linkedin.com/",
   businessName:'RepairsHub',
      publicKey:'pk_live_51PIqZhRqzjzYqY8kibfngyld14dalHoNnfMyrt0B8ivqkXmgPCxGkwcYLMebNS2XZYeP97kCasRS1svE4LkUktr900yaYGIBRA'
    }
    export default Env;  
    