import React, { useState , useEffect } from "react";
import { Col, Form, Button, Row, Container, Card } from "react-bootstrap";
import { FaSearch, FaShoppingCart, FaBars } from "react-icons/fa";
import "./Cart2.css";
import cart from "./cart.png";
import Navbar from "../Navbar/Navbar"
import { useNavigate } from "react-router-dom"; 
import axios from "axios";
import { loadStripe } from "@stripe/stripe-js";
import { MdCancel } from 'react-icons/md'; // Import the React Icons
import Env from '../Environment/Env'
import { PubKey } from "../PubKey/PubKey";
import { getAsset } from "../utils/helper";
import { Link } from "react-router-dom";
import Footer from "../Footer/Footer";
import { FaArrowLeft } from 'react-icons/fa'; 
import { toast, ToastContainer } from 'react-toastify'; // Added import for react-toastify
import 'react-toastify/dist/ReactToastify.css'; // Added import for react-toastify CSS

const ResponsiveSection = () => {
  const [showLoginForm, setShowLoginForm] = useState(true);
  const [showSignupForm, setShowSignupForm] = useState(false);
  const [showCheckoutForm, setShowCheckoutForm] = useState(false);
  const [OtpForVerification, setOtpForVerification] = useState(null);
  let navigate = useNavigate();
  const [ClickData, setClickData] = useState([]);
  const [productData, setProductData] = useState([]); // State hook to hold product data from localStorage
  const [TotalPrice, setTotalPrice] = useState(0); // State hook to hold product data from localStorage
  const [Message, setMessage] = useState(""); // State hook to hold product data from localStorage
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [DeliveryCharges, setDeliveryCharges] = useState([]);
  const [selectedOption, setSelectedOption] = useState('regular');
  const [IDLocal, setIDLocal] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false); // New state variable
  const [errors,setErrors] = useState({});
  const [ShowVerifySystem, setShowVerifySystem] = useState(false);
  const [showPass,setShowPass] = useState(false);

  useEffect(() => {
    setIDLocal(localStorage.getItem("id"));
  }, [localStorage.getItem("id")]);

  // Add this useEffect to update IDLocal state upon login
  useEffect(() => {
    const login = localStorage.getItem("role");
    if (login) {
      setIDLocal(localStorage.getItem("id"));
    }
  }, []);

  useEffect(()=>{
    getDeliveryCharges();
  },[])

  const getDeliveryCharges=()=>{
    axios.get(`${Env.server}/api/deliverycharges/getAll`)
    .then((res)=>{
      console.log("abc====", res.data);
      setDeliveryCharges(res.data.data);
    })
    .catch((err) => {
      console.log("err===>>>", err);
    })
  }



  useEffect(() => {
    const storedProducts = JSON.parse(localStorage.getItem('selectedProducts')) || [];

    // Store items into the hook
    setSelectedProducts(storedProducts);
    const parsedData = storedProducts;
    console.log("Abcd====", parsedData);
  

  }, [])

  const removeItem = (id) => {
    const updatedProductData = productData.filter(item => item.data._id !== id);
    setProductData(updatedProductData);
    localStorage.setItem("productData", JSON.stringify(updatedProductData));
  };


  const SubmitOrder = async () => {
    console.log("TotalPrice====", TotalPrice)
    if (selectedProducts.length === 0) {
      alert("Please Add Product, Your Cart is Empty");
    }else{

      const stripe = await loadStripe(PubKey);
      console.log("abc====", selectedProducts)
      let deliveryCharge = 0;
      
      if (deliveryMethod === "standard") {
        if (TotalPrice >= 15) {
          deliveryCharge = 0
        } else {
          deliveryCharge = DeliveryCharges.standard*100
      }
    } else if (deliveryMethod === "express") {
      if (TotalPrice >= 15) {
        deliveryCharge = 0
      } else {
        deliveryCharge = DeliveryCharges.express*100
      }
    }
    
    
  }
  }

  React.useEffect(() => {
    getDataByIDLocalStorage();
  }, [])


  const handleRemoveProduct = (index) => {
    const updatedSelectedProducts = [...selectedProducts];
    updatedSelectedProducts.splice(index, 1);
    setSelectedProducts(updatedSelectedProducts);
    // Optionally, update localStorage if needed
    localStorage.setItem('selectedProducts', JSON.stringify(updatedSelectedProducts));
};


  // Function to retrieve data by ID from localStorage
  const getDataByIDLocalStorage = () => {
    let data = localStorage.getItem("selectedProducts");
    const parsedData = JSON.parse(data);
    console.log("Abcd====", JSON.parse(data));
    if (data) {
      setProductData(JSON.parse(data)); // Parse the data and update the state

      let totalPrice = parsedData.reduce((total, item) => {
        console.log("hhhh", item);
        return total + (item.productPrice * item.quantity);
      }, 0);

      console.log("Total Price:", totalPrice);
      setTotalPrice(totalPrice)
    }
  }


  const handleOptionChange = (event) => {
    setSelectedOption(event?.target?.value);
    console.log(event?.target?.value);
  };

  const handleSubmit = () => {
    let login = localStorage.getItem("role");
    if (login) {
      console.log("abc=====>>>>", productData);
      let id = localStorage.getItem("id");
      let role = localStorage.getItem("role");
      let orderplaceData = {
        clientid: id,
        role: "forsale",
        name: localStorage.getItem("firstname") + " " + localStorage.getItem("lastname"),
        product: productData,
        totalprice: TotalPrice
      }
      console.log("abcbbecbeb======", orderplaceData);

      axios.post(`${Env.server}/api/order/neworder`, orderplaceData)
        .then((res) => {
          console.log("abc===>>>", res.data)
          console.log("abc===>>>", res.data.message)
          if (res.data.message === "success") {
            localStorage.removeItem("productData");
            setProductData([]);
            setTotalPrice(0);
            setMessage("Product Sell request sent Successfully. Please Check your email");
            const timer = setTimeout(() => {
              setMessage("");
              navigate("/");
            }, 5000);
            return () => clearTimeout(timer);
          }
        })
        .catch((err) => {
          console.log("err======", err);
        })

    } else {
      alert("Please Login First")
    }

  }

  const handleDelete = (index) => {
    // Create a copy of selectedProducts array
    const updatedProducts = [...selectedProducts];
    // Remove the product at the specified index
    updatedProducts.splice(index, 1);
    // Update the state with the modified array
    setSelectedProducts(updatedProducts);
    // Update the localStorage with the modified array
    localStorage.setItem('selectedProducts', JSON.stringify(updatedProducts));
    window.location.reload();
  };


  const handleLoginToggle = () => {
    setShowLoginForm(!showLoginForm);
    setShowSignupForm(false);
    setShowCheckoutForm(false);
  };

  const handleSignupToggle = () => {
    setShowSignupForm(!showSignupForm);
    setShowLoginForm(false);
    setShowCheckoutForm(false);
  };

  const handleCheckoutToggle = () => {
    setShowCheckoutForm(!showCheckoutForm);
    setShowSignupForm(false);
    setShowLoginForm(false);
  };

  const [deliveryMethod, setDeliveryMethod] = useState("standard");

  const handleChange = (event) => {
    setDeliveryMethod(event?.target?.value);
  };

  const [data, setData] = useState({});
  const [EmailforgotPass, setEmailforgotPass] = useState("");

 

  const handleChange1 = (e) => {
    let obj = data;
    obj[e.target.name] = e.target.value;
    setData(obj);
  }

  const submitLogin = () => {

    if (!data.email || !data.password) {
      toast.error("Please fill in all fields", {
        position: "top-center"
      });
      return;
    }
    console.log("abc====", data)
    axios.post(`${Env.server}/api/user/login`, data)
      .then((res) => {
        const resp = res.data.response;
        console.log("abc===>>>", res.data)
        localStorage.setItem("id", resp._id)
        localStorage.setItem("firstname", resp.firstname)
        localStorage.setItem("lastname", resp.lastname)
        localStorage.setItem("phNum", resp.phNum)
        localStorage.setItem("email", resp.email)
        if (resp.firstname === "admin") {
          localStorage.setItem("role", "admin"); 
          navigate("/");
        } else if (resp.firstname !== "admin") {
          localStorage.setItem("role", "seller"); 
          // alert("Signin Successfull")
          toast.success("Signin Successfull", {
            position: "top-center"
          });
           // Update IDLocal state
        setIDLocal(resp._id);
        // Close the signup form
        setShowSignupForm(false);    

        }
      })
      .catch((err) => {
        console.log("err===>>>", err);
        toast.error("Login failed. Please try again", {
          position: "top-center"
        });
      })
  }


  const forgotFun=()=>{
    if (!EmailforgotPass) {
      toast.error("Please provide your email!", {
        position: "top-center"
      });
      return;
    }
    axios.get(`${Env.server}/api/user/forgotPassword/${EmailforgotPass}` )
    .then((res)=>{
        console.log("Abc=====", res.data);
        toast.success("Password reset email sent", {
          position: "top-center"                                       
        });
        window.open("https://mail.google.com/", "_blank");
    })
    .catch((err) => {
        console.log("err", err)
        toast.error(err.response.data.message, {
          position: "top-center"
        });
    })
}
  const submitSignup = () => {
    if (!data.firstname || !data.lastname || !data.email || !data.phNum || !data.password || !data.conpassword) {
      toast.error("Please fill in all fields", {
        position: "top-center"
      });
      return;
    }

    // if(data.password !== data.conpassword){
    //   setErrors({...errors,["password"]:'Passwords do not match'});
    // }else{
    //   setErrors({...errors,["password"]:null});
    // }
    if (data.password !== data.conpassword) {
      // setErrors({...errors, ["password"]: 'Passwords do not match'});
      toast.error("Passwords do not match", {
        position: "top-center"
      });
      return;
    } else {
      setErrors({...errors, ["password"]: null});
    }
    if(errors.password){
      return;
    }
    let finalData = {
      ...data,
      allowemail: true,
      allowsms: true
    };
    console.log("finalData====", finalData);

    axios.post(`${Env.server}/api/user/signup`, finalData)
      .then((res) => {
        console.log("abc===>>>", res.data)
        setShowSignupForm(false)
        setShowVerifySystem(true)
        localStorage.setItem("email", res.data.user.email)
        toast.success("Signup Successfull", {
          position: "top-center"
        }); 
        // alert("Signup Successfull")
      })
      .catch((err) => {
        console.log("err===>>>", err);
        toast.error("Signup failed. Please try again", {
          position: "top-center"
        });
      })
  }

  const VerifyOTPFun = ()=>{
    axios.put(`${Env.server}/api/user/verifyEmail/${OtpForVerification}/${localStorage.getItem("email")}`)
    .then((res) => {
        console.log("abc===>>>", res.data)
        
        if (res.data.message === "Email Verified Successfully") {
          toast.success('Email verified successfully!', {
            position: 'top-center',
        });
          navigate("/Singin")
        }
    })
    .catch((err) => {
      toast.error('Failed to verify email. Please try again.', {
        position: 'top-center', // Updated position
    });
        console.log("err===>>>", err);
    })
  }

  return (
  <>
      <Navbar />
      <Container
        fluid
        style={{ marginTop: "60px", marginBottom: "60px", paddingLeft: "50px", paddingRight: "50px" }}
      >
        {/* <Link to="/Cart1">
          <FaArrowLeft className="faarrowww"  style={{margin: '20px 20px' ,cursor: "pointer", fontSize: '30px' }} />
        </Link> */}
        <Link to="/Cart1" style={{display: "block", width: '5%'}}>
          <FaArrowLeft className="faarrowww"  style={{ cursor: "pointer", fontSize: '30px', marginBottom: '20px' }} />
          </Link>
        <h2 className="ml-5 mb-4">Account</h2>
        <Row className="justify-content-between ">
          <Col lg={6}>
       
        {!IDLocal ? (
              // Render login, signup, or guest checkout form
              <>
                <Form className="form-for-login">
              <Form.Group className="mb-4">
                <Form.Check
                  type="radio"
                  id="login"
                  label="Login"
                  checked={showLoginForm}
                  onChange={handleLoginToggle}
                  style={{
                    border: "1px solid #212059",
                    borderRadius: "15px",
                    paddingLeft: "30px",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    backgroundColor: "#F9FAFB",
                  }}
                  className="custom-radio"
                />
                {showLoginForm && (
                  <div
                    style={{
                      border: "1px solid #212059",
                      borderRadius: "15px",
                      padding: "10px 7px 10px 7px",
                      marginTop: "15px",
                      marginBottom: "15px",
                    }}
                  >
                    <h5
                      style={{
                        textAlign: "center",
                        fontWeight: "bold",
                        marginBottom: "20px",
                        fontSize: "20px",
                      }}
                    >
                      Login details
                    </h5>
                    <Row>
                      <Col>
                        <div>
                          <h5 style={{ marginLeft: "9px" }}>Email*</h5>
                          <Form.Control type="text" placeholder="Email" onChange={handleChange1} name="email" />
                        </div>
                      </Col>
                      <Col>
                        <div>
                          <h5 style={{ marginLeft: "9px" }}>Password*</h5>
                          <Form.Control type="password" placeholder="Password" onChange={handleChange1} name="password" />
                        </div>
                      </Col>
                    </Row>
                    <div
                      style={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <Button
                        style={{
                          color: "#DB241B",
                          textDecoration: "none",
                          fontStyle: "italic",
                        }}
                        variant="link"
                        className="mt-2"
                        data-toggle="modal"
                        data-target="#exampleModalLong1"
                      >
                        Forgot Password?
                      </Button>
                      <Button
                        style={{
                          backgroundColor: "#DB241B",
                          border: "none",
                          borderRadius: "20px",
                          marginRight: "12px",
                          width: "90px",
                          height: "35px",
                        }}
                        variant="primary" 
                        className="mt-2"
                        onClick={submitLogin}
                      >
                        Login
                      </Button>
                    </div>
                  </div>
                )}
              </Form.Group>

              <Form.Group className="mb-4">
                <Form.Check
                  type="radio"
                  id="signup"
                  label="Sign Up"
                  checked={showSignupForm}
                  onChange={handleSignupToggle}
                  style={{
                    border: "1px solid #212059",
                    borderRadius: "15px",
                    paddingLeft: "30px",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    backgroundColor: "#F9FAFB",
                  }}
                  className="custom-radio"
                />
                
                {showSignupForm && (
                  <div
                    style={{
                      border: "1px solid #212059",
                      borderRadius: "15px",
                      padding: "10px 7px 10px 7px",
                      marginTop: "15px",
                    }}
                  >
                    <h5
                      style={{
                        textAlign: "center",
                        fontWeight: "bold",
                        marginBottom: "25px",
                        fontSize: "20px",
                      }}
                    >
                      Signup details
                    </h5>
                    <Row>
                      <Col>
                        <div>
                          <h5 style={{ marginLeft: "9px" }}>First Name*</h5>
                          <Form.Control type="text" placeholder="First Name" onChange={handleChange1} name="firstname" />
                        </div>
                      </Col>
                      <Col>
                        <div>
                          <h5 style={{ marginLeft: "9px" }}>Last Name*</h5>
                          <Form.Control type="text" name="lastname" onChange={handleChange1} placeholder="Last Name" />
                        </div>
                      </Col>
                    </Row>
                    <Row className="mt-2">
                      <Col>
                        <div>
                          <h5 style={{ marginLeft: "9px" }}>Phone Number*</h5>
                          <Form.Control type="number" name="phNum" onChange={handleChange1} placeholder="Phone Number" />
                        </div>
                      </Col>
                      <Col>
                        <div>
                          <h5 style={{ marginLeft: "9px" }}>Email*</h5>
                          <Form.Control type="text" name="email" onChange={handleChange1} placeholder="Email" />
                        </div>
                      </Col>
                    </Row>
                    <Row className="mt-2">
                      <Col>
                        <div>
                          <h5 style={{ marginLeft: "9px" }}>Password*</h5>
                          <Form.Control type={showPass ? "text" : "password"} name="password" onChange={handleChange1} placeholder="Password" />
                          {errors.password && <p style={{color:'red'}} >{errors.password}</p>}
                        </div>
                      </Col>
                      <Col>
                        <div>
                          <h5 style={{ marginLeft: "9px" }}>Confirm Password*</h5>
                          <Form.Control type={showPass ? "text" : "password"} name="conpassword" onChange={handleChange1} placeholder="Confirm Password" />
                          {errors.password && <p style={{color:'red'}} >{errors.password}</p>}
                        </div>
                      </Col>
                    </Row>
                    <Row className="mt-2">
                      <Col>
                        <div style={{display:'flex',alignContent:'center',cursor:'pointer',height:'20px',margin:'2px 8px'}} >
                          <input id="showpass" type="checkbox" checked={showPass} onClick={()=>setShowPass(prev=>!prev)} />
                          <label htmlFor="showpass" style={{margin:'7px 4px'}} >Show Password</label>
                        </div>
                      </Col>
                    </Row>
                    <div
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      {/* <Button
                        style={{
                          color: "#DB241B",
                          textDecoration: "none",
                          fontStyle: "italic",
                        }}
                        variant="link"
                        className="mt-2"
                        data-toggle="modal"
                        data-target="#exampleModalLong1"
                      >
                        Forgot Password?
                      </Button> */}
                      <Button
                        style={{
                          backgroundColor: "#DB241B",
                          border: "none",
                          borderRadius: "20px",
                          marginRight: "12px",
                          width: "90px",
                          height: "35px",
                        }}
                        onClick={submitSignup}
                        variant="primary" 
                        className="mt-2"
                      >
                        Register
                      </Button>
                    </div>
                  </div>
                )}

{ShowVerifySystem && (
  <>
    {/* Email verification form */}
    <div className="form justify-content-center align-items-center">
      <div className="form-content">
        <h1 className="fs-7" style={{color: '#071A44'}}>Sign Up</h1>
        <p style={{ font: "20px", color: '#071A44'}}>
          Already have an account? 
          <Link to="/Singin">
            <span className="fw-bolder" class="about-link">
              <b>Sign in here</b>
            </span>
          </Link>
        </p>
        <h2>Verify Email</h2>
        <input 
          type="text" 
          placeholder="Enter OTP" 
          name="OTP" 
          onChange={(e) => setOtpForVerification(e.target.value)} 
        />
        <button className="btn btn-primary" onClick={VerifyOTPFun}>Verify Email</button>
        <br/>
        <p>Please Check the Email, and Enter the OTP</p>
      </div>
    </div>
  </>
)}
              </Form.Group>

              <Form.Group className="mb-4">
                <Form.Check
                  type="radio"
                  id="checkout"
                  label="Continue as Guest"
                  checked={showCheckoutForm}
                  onChange={handleCheckoutToggle}
                  style={{
                    border: "1px solid #212059",
                    borderRadius: "15px",
                    paddingLeft: "30px",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    backgroundColor: "#F9FAFB",
                  }}
                  className="custom-radio"
                />
              </Form.Group>
            </Form>
              </>
            ) : (
              
              <>
                <Card
                  style={{
                    borderRadius: "20px",
                    padding: "20px",
                    border: "2px solid #212059",
                  }}
                >
                  <h4>User Credentials</h4>
                  <p><strong>Name:</strong> {localStorage.getItem("firstname")} {localStorage.getItem("lastname")}</p>
                  <p><strong>Email:</strong> {localStorage.getItem("email")}</p>
                  <p><strong>Phone Number:</strong> {localStorage.getItem("phNum")}</p>
                  <p><strong>Role:</strong> {localStorage.getItem("role")}</p>
                </Card>
              </>
            )}


           
          </Col>

          <Col lg={4} className="mt-lg-0">
            {/* <h2>Order Summary</h2> */}
           
                          
                          <div className="col-12 " style={{ padding: "0px",marginTop: '0px'}}>

                 <div style={{borderRadius: '20px', paddingTop: '10px',  paddingRight: '10px' ,fontSize: '25px', paddingLeft: "10px", border: "1px solid lightgray",backgroundColor: '#F8F6ED'}}>
            <h5 className="text-center" style={{color: '#000000', fontWeight: 'bold', marginBottom: '7px'}}>Your order summary</h5>
            <div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
    <p className="col-3" style={{ fontWeight: '600', marginBottom: '0', fontSize: '17px' }}>Product</p>
    <p className="col-2" style={{ fontWeight: '500', marginBottom: '0', fontSize: '17px' , fontWeight:"bolder" }}>Price</p>
    <p className="col-2" style={{ fontWeight: '500', marginBottom: '0', fontSize: '17px' , fontWeight:"bolder" }}>Qty</p>
    <p className="col-3" style={{ fontWeight: '500', marginBottom: '0', fontSize: '17px' , fontWeight:"bolder" }}>T.Price</p>
    <p className="col-2 hidden-element" style={{ fontWeight: '500', marginBottom: '0', fontSize: '7px' , fontWeight:"bolder", visibility: 'hidden' }}>Price</p> 

  </div>
  {selectedProducts.map((item, index) => (
    <>
        <div key={index} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <p className="col-3" style={{ fontWeight: '600', marginBottom: '0', fontSize: '13px' }}>
            {item?.subsubcatagory}
            {item.selectedColor && <p>{`(${item.selectedColor.name || item.selectedColor })`}</p>}
          </p>
          <p className="col-2" style={{ fontWeight: '500', marginBottom: '0', fontSize: '13px' }}>£{item.productPrice}</p>
          <p className="col-2" style={{ fontWeight: '600', marginBottom: '0', fontSize: '13px' }}>{item?.quantity}</p>
          <p className="col-3" style={{ fontWeight: '500', marginBottom: '0', fontSize: '13px' }}>£{(item.productPrice * item?.quantity).toFixed(2)}</p>
          <MdCancel className="col-2" onClick={() => handleRemoveProduct(index)} style={{fontSize: '20px', color: 'red', cursor: 'pointer',  margin: 'auto' }} />
        </div>
        <hr/>
    </>
      ))}

                <br/> 
                
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <p style={{ fontWeight: '600', marginBottom: '0', fontSize: '13px' }}>Total Price</p>
        <p style={{ fontWeight: '500', marginBottom: '0', fontSize: '13px' }}>
          £{selectedProducts?.reduce((total, item) => total + parseFloat(item?.productPrice * item?.quantity), 0).toFixed(2)}
        </p>
      </div>
        <br/>
                
                <Link to="/Cart3">
                <button
                    style={{
                      color: "black",
                      borderRadius: '50px',
                      backgroundColor: '#F8D803',
                      fontSize: "10px", 
                      padding: '10px 10px 10px 10px',
                      width: '70%',
                      marginTop: '5px',
                      border: 'none',
                      fontWeight: 'bold',
                      display: 'flex',
                      justifyContent: 'center',
                      margin: 'auto',
                      marginBottom: '8px',
                      alignItems: 'center'
                      
                    }}
                  >
                    Continue to checkout
                </button>
                </Link>
                
             
            </div>
          </div>
            </div>
            
          </Col>

        
        </Row>
      </Container>
      <Footer />

         

      <div class="modal fade" id="exampleModalLong1" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLongTitle">Email</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
<input type="email" onChange={(e)=>setEmailforgotPass(e.target.value)} class="form-control" placeholder="Email Address"/>
                        </div>

                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button type="button"
                                class="btn btn-primary"
                                data-dismiss="modal"
                                onClick={forgotFun}
                                   >Confirm</button>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer /> {/* Added ToastContainer for toast notifications */}
  </>
  );
};


export default ResponsiveSection;
