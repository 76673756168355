import React, { useEffect, useState } from "react";
// import "./Banar.css"
import Navbar from "../Navbar/Navbar";
import { Link } from "react-router-dom";
import axios from "axios";
import Env from "../Environment/Env";
import { getAsset } from "../utils/helper";
import { useNavigate } from "react-router-dom";
import {Paper, Grid, Card, CardContent} from '@mui/material'
import { useParams } from "react-router-dom";

const BrandPage = () => {
const {brand } = useParams();
const navigate = useNavigate();
   const accessories = [
    "Phone Cases",
    "Screen Protectors",
    "Charging Cables",
    "Wireless Chargers",
    "Phone Grips",
    "PopSockets",
    "Selfie Sticks",
    "Bluetooth Headsets",
    "Car Mounts",
    "Phone Stands"
  ];


  const [data , setData] = useState([]);

  useEffect(() => {
    axios
      .get(`${Env.server}/api/subsubcatagory/GetModelByAcc/${brand}`)
      .then((res) => {
        console.log("brand=====", res.data.subsubcatagories);  
        setData(res.data.subsubcatagories) 
      })
      .catch((err) => {
        console.log("err====", err);
      })
  }, [])


  const uniqueSubcategoriesBrand = new Set(data.map(card => card.subcatagory));

  // Convert Set back to an array
  const uniqueSubcategoriesArrayBrand = [...uniqueSubcategoriesBrand];


    const cardData = [
        { 
          title: "Card 1", 
          image: "https://mdbootstrap.com/img/Photos/Others/images/15.jpg", 
          content: "Some quick example text to build on the card title and make up the bulk of the card's content." 
        },
        { 
          title: "Card 2", 
          image: "https://mdbootstrap.com/img/Photos/Others/images/15.jpg", 
          content: "Some quick example text to build on the card title and make up the bulk of the card's content." 
        },
        { 
          title: "Card 3", 
          image: "https://mdbootstrap.com/img/Photos/Others/images/15.jpg", 
          content: "Some quick example text to build on the card title and make up the bulk of the card's content." 
        },
        { 
          title: "Card 4", 
          image: "https://mdbootstrap.com/img/Photos/Others/images/15.jpg", 
          content: "Some quick example text to build on the card title and make up the bulk of the card's content." 
        }
      ];

      const [isSelected, setIsSelected] = useState(false);

      const handleCheckboxChange = () => {
        setIsSelected(!isSelected);
      };




    return (
        <>
       
            <Navbar />      
        



        <div className="container-fluid">
          <h3>Brand Description</h3>
          <p>Select your device model to discover a wide range of compatible accessories tailored just for you. Choose your device model to explore our extensive collection of accessories designed to enhance your experience.
          </p>
        </div>

        <div className="container-fluid">
        <div className="row">

        <div className="container-fluid pl-0 pr-0">
  <div style={{ width: '100%', backgroundColor: '#F8F8F8', padding: '10px', borderRadius: '3px', overflow: 'hidden', textAlign: 'center' }}>
    <h3 style={{ marginTop: '1rem', marginBottom: '0.5rem', fontSize: '1.5rem' }}>All Model</h3>
    <div className="row">
      
                  {uniqueSubcategoriesArrayBrand.map((data , index)=>(
                    <>
                      <div key={index} className="col-lg-3 col-md-6 col-sm-6" style={{ cursor: "pointer" }} onClick={() => navigate(`/Model/${brand}/${encodeURIComponent(data)}`)}>
        <div style={{ marginBottom: '0.5rem' }}>
                          <h6 style={{ marginBottom: '0', fontSize: '1rem' }}>{data}</h6>
        </div>
      </div>
                    </>
                  ))}
    
    </div>
  </div>
        </div>






          </div>
        </div>           
        </>
    )
}
export default BrandPage;


