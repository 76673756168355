import React, { useState } from "react";
// import "./singup.css"
import Navbar from "../../Navbar/Navbar1";
import Footer from "../../Footer/Footer";
import { Link } from "react-router-dom";
import axios from "axios";
import Env from "../../Environment/Env";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify'; // Added import for react-toastify
import 'react-toastify/dist/ReactToastify.css'; // Added import for react-toastify CSS
const Singup = () => {
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [emailChecked, setEmailChecked] = useState(false);
    const [smsChecked, setSmsChecked] = useState(true);
    const [ShowVerifySystem, setShowVerifySystem] = useState(false);
    const [OtpForVerification, setOtpForVerification] = useState(null);
    

    const handleEmailChange = () => {
        setEmailChecked(!emailChecked);
    };

    const handleSmsChange = () => {
        setSmsChecked(!smsChecked);
    };


    const handleChange = (e) => {
        let obj = data;
        obj[e.target.name] = e.target.value;
        setData(obj);
    }

    const submit = () => {

        let finalData = {
            ...data,
            allowemail: emailChecked,
            allowsms: smsChecked
        };
        console.log("finalData====", finalData);

        axios.post(`${Env.server}/api/user/signup`, finalData)
            .then((res) => {
                console.log("abc===>>>", res.data) 
                if (res.data.message === "success") {
                  setShowVerifySystem(true)
                  localStorage.setItem("email", res.data.user.email)
                  toast.success('Signup successful! Please check your email for verification.', {
                    position: 'top-center', // Updated position
                });
                }
                else {
                  toast.error('Signup failed! Please try again.', {
                    position: 'top-center', // Updated position
                });
              }
            })
            .catch((err) => {
                console.log("err===>>>", err);
                toast.error('An error occurred during signup. Please try again.', {
                  position: 'top-center', // Updated position
              });
            })
    }


    const VerifyOTPFun = ()=>{
      axios.put(`${Env.server}/api/trader/verifyEmail/${OtpForVerification}/${localStorage.getItem("email")}`)
      .then((res) => {
          console.log("abc===>>>", res.data) 
          if (res.data.message === "Email Verified Successfully") {
            window.location.href = `${Env.tradeSite}/TradeSignin`;
            toast.success('Email verified successfully!', {
              position: 'top-center', // Updated position
          });
          }
          else {
            toast.error('OTP verification failed. Please try again.', {
              position: 'top-center', // Updated position
          });
        }
      })
      .catch((err) => {
          console.log("err===>>>", err);
          toast.error('An error occurred during OTP verification. Please try again.', {
            position: 'top-center', // Updated position
        });
      })
    }

// Trading Site

const [formDataTrade, setFormDataTrade] = useState({
  role: 'trader',
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    companyName: '',
    companyAddress: '',
    address: '',
    postcode: '',
    city: '',
    contactNumber: '',
    tradingContactNumber: '',
    tradingAddress: '',
    currentlySellingPhones: false,
    moreInformation: '',
    status: 'inactive'
  });

  const handleChangeTrade = (event) => {
    const { name, value, type, checked } = event.target;
    const newValue = type === 'checkbox' ? checked : value;
    setFormDataTrade({
      ...formDataTrade,
      [name]: newValue
    });
  };


  const validateForm = (formData) => {
    const requiredFields = [
        "firstName",
        "lastName",
        "email",
        "password",
        "companyName",
        "companyAddress",
        "address",
        "postcode",
        "city",
        "contactNumber",
        "tradingContactNumber",
        "tradingAddress"
    ];
    for (let field of requiredFields) {
        if (!formData[field]) {
            return false;
        }
    }
    return true;
};


  const handleSubmitTrade = (event) => {
    event.preventDefault();


    if (!validateForm(formDataTrade)) {
      toast.error('Please fill in all required fields.', {
        position: 'top-center', // Updated position
    });
      return;
  }

  
    // Do something with the form data, like sending it to a server
    console.log('Submitted:', formDataTrade);
    axios.post(`${Env.server}/api/trader/signup`, formDataTrade)
    .then((res) => {
        console.log("abc===>>>", res.data)
        if (res.data.message === "success") {
          localStorage.setItem("email", res.data.user.email)
          setShowVerifySystem(true)
          toast.success('Trader signup successful! Please check your email for verification.', {
            position: 'top-center', // Updated position
        });
        }
        else {
          toast.error('Trader signup failed! Please try again.', {
            position: 'top-center', // Updated position
        });
      }
    })
    .catch((err) => {
        console.log("err===>>>", err);
        toast.error('An error occurred during trader signup. Please try again.', {
          position: 'top-center', // Updated position
      });
    })

  };



  const [retailshow , setRetailshow] = useState(false);

 



    return (
        <>
            <Navbar /><br /><br /><br />
            <div class="container register-form pt-5   w-75">
                <div class="form justify-content-center align-items-center">
                    <div class="form-content ">
                    
                    
  <h1 className="fs-7" style={{color: '#071A44', marginRight: '20px'}}>Sign Up</h1>
                    {/* <div className="container" style={{display: 'flex', alignItems: 'center'}}>
    <button class="btn btn-primary" style={{border:"none" , backgroundColor: `${retailshow ? 'green' : 'red'}`  , borderRadius:"10px"}} onClick={()=>setRetailshow(true)}>Retail Site</button>
    <button class="btn btn-primary" style={{border:"none", marginLeft:"10px" , backgroundColor:`${retailshow ? 'red' : 'green'}` , borderRadius:"10px"}} onClick={()=>setRetailshow(false)}>Trading Site</button>
</div> */}


                        <p style={{ font: "20px", color: '#071A44'}}>Already have an account? <Link to="/TradeSignin"><span className="fw-bolder" class="about-link"><b>Sign in here</b></span></Link> </p>
                      

                        {ShowVerifySystem === false ? (
                          <>
                             
                            <>
  <div className="row">
    <div className="col-md-6">
      <div className="form-group">
        <label>First Name:</label>
        <input
          type="text"
          name="firstName"
          // value={formDataTrade.firstName}
          onChange={handleChangeTrade}
          className="form-control"
          placeholder="Enter First Name"
          required
        />
      </div>
    </div>
    <div className="col-md-6">
      <div className="form-group">
        <label>Last Name:</label>
        <input
          type="text"
          name="lastName"
          // value={formDataTrade.lastName}
          onChange={handleChangeTrade}
          className="form-control"
          placeholder="Enter Last Name"
          required
        />
      </div>
    </div>
  </div>
  <div className="row">
    <div className="col-md-6">
      <div className="form-group">
        <label>Email:</label>
        <input
          type="email"
          name="email"
          // value={formDataTrade.email}
          onChange={handleChangeTrade}
          className="form-control"
          placeholder="Enter Email"
          required
        />
      </div>
    </div>
    <div className="col-md-6">
      <div className="form-group">
        <label>Password:</label>
        <input
          type="password"
          name="password"
          // value={formDataTrade.password}
          onChange={handleChangeTrade}
          className="form-control"
          placeholder="Enter Password"
          required
        />
      </div>
    </div>
  </div>
  <div className="row">
    <div className="col-md-6">
      <div className="form-group">
        <label>Company Name:</label>
        <input
          type="text"
          name="companyName"
          // value={formDataTrade.companyName}
          onChange={handleChangeTrade}
          className="form-control"
          placeholder="Enter Company Name"
          required
        />
      </div>
    </div>
    <div className="col-md-6">
      <div className="form-group">
        <label>Company Address:</label>
        <input
          type="text"
          name="companyAddress"
          // value={formDataTrade.companyAddress}
          onChange={handleChangeTrade}
          className="form-control"
          placeholder="Enter Company Address"
          required
        />
      </div>
    </div>
  </div>
  <div className="row">
    <div className="col-md-6">
      <div className="form-group">
        <label>Address:</label>
        <input
          type="text"
          name="address"
          // value={formDataTrade.address}
          onChange={handleChangeTrade}
          className="form-control"
          placeholder="Enter Address"
          required
        />
      </div>
    </div>
    <div className="col-md-6">
      <div className="form-group">
        <label>Postcode:</label>
        <input
          type="text"
          name="postcode"
          // value={formDataTrade.postcode}
          onChange={handleChangeTrade}
          className="form-control"
          placeholder="Enter Postcode"
          required
        />
      </div>
    </div>
  </div>
  <div className="row">
    <div className="col-md-6">
      <div className="form-group">
        <label>City:</label>
        <input
          type="text"
          name="city"
          // value={formDataTrade.city}
          onChange={handleChangeTrade}
          className="form-control"
          placeholder="Enter City"
          required
        />
      </div>
    </div>
    <div className="col-md-6">
      <div className="form-group">
        <label>Contact Number:</label>
        <input
          type="tel"
          name="contactNumber"
          // value={formDataTrade.contactNumber}
          onChange={handleChangeTrade}
          className="form-control"
          placeholder="Enter Contact Number"
          required
        />
      </div>
    </div>
  </div>
  <div className="row">
    <div className="col-md-6">
      <div className="form-group">
        <label>Trading Contact Number:</label>
        <input
          type="tel"
          name="tradingContactNumber"
          // value={formDataTrade.tradingContactNumber}
          onChange={handleChangeTrade}
          className="form-control"
          placeholder="Enter Trading Contact Number"
          required
        />
      </div>
    </div>
    <div className="col-md-6">
      <div className="form-group">
        <label>Trading Address:</label>
        <input
          type="text"
          name="tradingAddress"
          // value={formDataTrade.tradingAddress}
          onChange={handleChangeTrade}
          className="form-control"
          placeholder="Enter Trading Address"
          required
        />
      </div>
    </div>
  </div>
  <div className="form-group">
    <label>Do you currently sell phones?</label> 
     <textarea
      name="currentlySellingPhones" 
      onChange={handleChangeTrade}
      className="form-control"
      placeholder="Do You Selling Phones Currently?"
    />
  </div>
  <div className="form-group">
    <label>Can you provide more information on it?</label>
    
    <textarea
      name="moreInformation"
      // value={formDataTrade.moreInformation}
      onChange={handleChangeTrade}
      className="form-control"
      placeholder="Enter More Information"
    />
  </div>
  <button type="button" onClick={handleSubmitTrade} class="btnSubmit btn-lg mt-2 bg-white fw-bolder fs-9" style={{color: '#071A44', boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)'}}>Submit Form</button>
</>
  

                        </>
                        ):(
                          <>
                            <h2>Verify Email</h2>
                            <input type="text" placeholder="Enter OTP" name="OTP" onChange={(e) => setOtpForVerification(e.target.value)} />
                            <button className="btn btn-primary" onClick={VerifyOTPFun} >Verify Email</button>

                            <br/>
                            <p>Please Check the Email, and Enter the OTP</p>
                          </>
                        ) }

                    </div>


                   
                </div>
            </div>
            <Footer />
            <ToastContainer /> {/* Added ToastContainer for toast notifications */}
        </>
    )
}
export default Singup;