import React, { useState } from "react";
import "./singup.css"
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import { Link } from "react-router-dom";
import axios from "axios";
import Env from "../Environment/Env";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify'; // Added import for react-toastify
import 'react-toastify/dist/ReactToastify.css'; // Added import for react-toastify CSS
const Singup = () => {
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [emailChecked, setEmailChecked] = useState(false);
    const [smsChecked, setSmsChecked] = useState(true);
    const [ShowVerifySystem, setShowVerifySystem] = useState(false);
    const [OtpForVerification, setOtpForVerification] = useState(null);
    const [error, setError] = useState('');
    const [signUpLoader,setSignUpLoader] = useState(false);

    const handleEmailChange = () => {
        setEmailChecked(!emailChecked);
    };

    const handleSmsChange = () => {
        setSmsChecked(!smsChecked);
    };


    
    const handleChange = (e) => {
        let obj = data;
        obj[e.target.name] = e.target.value;
        setData(obj);
        console.log("data====", data);
    }

    const submit = async () => {
      if (!data.firstname || !data.lastname || !data.email || !data.password || !data.confirmpassword || !data.phNum) {
        toast.error('Please fill in all fields', {
            position: 'top-center',
        });
        console.log('aaaaa==>>',data);
        return;
    }

    
    let finalData = {
      ...data,
      allowemail: emailChecked,
      allowsms: smsChecked
  };

    if(data.password === data.confirmpassword){
      setSignUpLoader(true);

      await axios.post(`${Env.server}/api/user/signup`, finalData)
      .then((res) => {
          console.log("abc===>>>", res.data) 
          if (res.data.message === "success") {
            setShowVerifySystem(true)
            localStorage.setItem("email", res.data.user.email)
            toast.success('Signup successful! Please verify your email.', {
              position: 'top-center',
          });
          }
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          position: 'top-center',
      });
          console.log("err===>>>", err);
      })
      setSignUpLoader(false);
    }else{
      
      // alert("Password does not match")
      toast.error('Password does not match', {
        position: 'top-center', // Updated position
    });
    
    }


      

       
    }

    // Add this just after the error state declaration
const handleCheckboxClick = () => {
  setError('');
};


    const VerifyOTPFun = ()=>{
      axios.put(`${Env.server}/api/user/verifyEmail/${OtpForVerification}/${localStorage.getItem("email")}`)
      .then((res) => {
          console.log("abc===>>>", res.data)
          
          if (res.data.message === "Email Verified Successfully") {
            toast.success('Email verified successfully!', {
              position: 'top-center',
          });
            navigate("/Singin")
          }
      })
      .catch((err) => {
        toast.error('Failed to verify email. Please try again.', {
          position: 'top-center', // Updated position
      });
          console.log("err===>>>", err);
      })
    }

// Trading Site

const [formDataTrade, setFormDataTrade] = useState({
  role: 'trader',
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    companyName: '',
    companyAddress: '',
    address: '',
    postcode: '',
    city: '',
    contactNumber: '',
    tradingContactNumber: '',
    tradingAddress: '',
    currentlySellingPhones: false,
    moreInformation: '',
    status: 'inactive'
  });

  const handleChangeTrade = (event) => {
    const { name, value, type, checked } = event.target;
    const newValue = type === 'checkbox' ? checked : value;
    setFormDataTrade({
      ...formDataTrade,
      [name]: newValue
    });
  };

  const handleSubmitTrade = (event) => {
    event.preventDefault();
    // Do something with the form data, like sending it to a server
    console.log('Submitted:', formDataTrade);
    axios.post(`${Env.server}/api/trader/signup`, formDataTrade)
    .then((res) => {
        console.log("abc===>>>", res.data)
        if (res.data.message === "success") {
            navigate("/Singin");
        }
    })
    .catch((err) => {
        console.log("err===>>>", err);
    })

  };



  const [retailshow , setRetailshow] = useState(true);

 



    return (
        <>
            <Navbar /><br /><br /><br />
            <div class="container register-form pt-5   w-75">
                <div class="form justify-content-center align-items-center">
                    <div class="form-content ">

                    <h1 className="fs-7" style={{color: '#071A44'}}>Sign Up</h1>

                        <p style={{ font: "20px", color: '#071A44'}}>Already have an account? <Link to="/Singin"><span className="fw-bolder" class="about-link"><b>Sign in here</b></span></Link> </p>
                      

                        {ShowVerifySystem === false ? (
                          <>
                            

                        
                        {retailshow? (
                            <>

                        <div class="row pt-4">

                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="First name " className="fs-9" style={{color: '#071A44'}}> First name*</label>
                                    <input type="text" class="form-control" name="firstname" onChange={handleChange} placeholder="First name" />
                                </div>
                                <div class="form-group">
                                    <label for="Email Address" className="fs-9" style={{color: '#071A44'}}>Email Address*</label>
                                    <input type="text" class="form-control" placeholder="Email Address" name="email" onChange={handleChange} />
                                </div>
                                <div class="form-group">
                                    <label for="Confirm Password" className="fs-9" style={{color: '#071A44'}}>Confirm Password*</label>
                                    <input type="password" class="form-control" placeholder="Confirm Password" name="confirmpassword" onChange={handleChange} />
                                   
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="Last name" className="fs-9" style={{color: '#071A44'}} >Last name*</label>
                                    <input type="text" class="form-control" placeholder="Last name" onChange={handleChange} name="lastname" />
                                </div>
                                <div class="form-group">
                                    <label for="Password" className="fs-9" style={{color: '#071A44'}}>Password*</label>
                                    <input type="password" class="form-control" placeholder="Password" onChange={handleChange} name="password" />
                                </div>
                                <div class="form-group">
                                    <label for="Contact Number" className="fs-9" style={{color: '#071A44'}}>Contact Number*</label>
                                    <input type="number" class="form-control" placeholder="Contact Number" onChange={handleChange} name="phNum" />
                                </div>
                            </div>


                        </div>

                        {/* // Inside your return statement, after the checkbox inputs */}
{error && <div className="alert alert-danger">{error}</div>}
{!emailChecked || !smsChecked && <div className="alert alert-warning">Please check both email and SMS options</div>}

                        <div className="form-check">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                name="allowemail"
                                id="flexCheckDefault"
                                checked={emailChecked}
                                onChange={handleEmailChange}
                                onClick={handleCheckboxClick} // Add this line
                            />
                            <label className="form-check-label fs-9" style={{color: '#071A44'}} htmlFor="flexCheckDefault">
                                Keep me up to date via email
                            </label>
                        </div>
                        <div className="form-check">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                id="flexCheckChecked"
                                name="allowsms"
                                checked={smsChecked}
                                onChange={handleSmsChange}
                                onClick={handleCheckboxClick} // Add this line
                            />
                            <label className="form-check-label fs-9" style={{color: '#071A44'}} htmlFor="flexCheckChecked" >
                                Keep me up to date via SMS
                            </label>
                        </div>

                        <button type="button" 
                        className="btnSubmit btn-lg mt-2 bg-white fw-bolder fs-9" 
                        style={{color: '#071A44',backgroungColor:(signUpLoader?'#97BE5A':''), boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)'}} 
                        onClick={submit} >{signUpLoader ? 'Creating....' : 'Create account'}</button>

                        </>
                        ):(
                            <>
  <div className="row">
    <div className="col-md-6">
      <div className="form-group">
        <label>First Name:</label>
        <input
          type="text"
          name="firstName"
          // value={formDataTrade.firstName}
          onChange={handleChangeTrade}
          className="form-control"
          placeholder="Enter First Name"
          required
        />
      </div>
    </div>
    <div className="col-md-6">
      <div className="form-group">
        <label>Last Name:</label>
        <input
          type="text"
          name="lastName"
          // value={formDataTrade.lastName}
          onChange={handleChangeTrade}
          className="form-control"
          placeholder="Enter Last Name"
          required
        />
      </div>
    </div>
  </div>
  <div className="row">
    <div className="col-md-6">
      <div className="form-group">
        <label>Email:</label>
        <input
          type="email"
          name="email"
          // value={formDataTrade.email}
          onChange={handleChangeTrade}
          className="form-control"
          placeholder="Enter Email"
          required
        />
      </div>
    </div>
    <div className="col-md-6">
      <div className="form-group">
        <label>Password:</label>
        <input
          type="password"
          name="password"
          // value={formDataTrade.password}
          onChange={handleChangeTrade}
          className="form-control"
          placeholder="Enter Password"
          required
        />
      </div>
    </div>
  </div>
  <div className="row">
    <div className="col-md-6">
      <div className="form-group">
        <label>Company Name:</label>
        <input
          type="text"
          name="companyName"
          // value={formDataTrade.companyName}
          onChange={handleChangeTrade}
          className="form-control"
          placeholder="Enter Company Name"
          required
        />
      </div>
    </div>
    <div className="col-md-6">
      <div className="form-group">
        <label>Company Address:</label>
        <input
          type="text"
          name="companyAddress"
          // value={formDataTrade.companyAddress}
          onChange={handleChangeTrade}
          className="form-control"
          placeholder="Enter Company Address"
          required
        />
      </div>
    </div>
  </div>
  <div className="row">
    <div className="col-md-6">
      <div className="form-group">
        <label>Address:</label>
        <input
          type="text"
          name="address"
          // value={formDataTrade.address}
          onChange={handleChangeTrade}
          className="form-control"
          placeholder="Enter Address"
          required
        />
      </div>
    </div>
    <div className="col-md-6">
      <div className="form-group">
        <label>Postcode:</label>
        <input
          type="text"
          name="postcode"
          // value={formDataTrade.postcode}
          onChange={handleChangeTrade}
          className="form-control"
          placeholder="Enter Postcode"
          required
        />
      </div>
    </div>
  </div>
  <div className="row">
    <div className="col-md-6">
      <div className="form-group">
        <label>City:</label>
        <input
          type="text"
          name="city"
          // value={formDataTrade.city}
          onChange={handleChangeTrade}
          className="form-control"
          placeholder="Enter City"
          required
        />
      </div>
    </div>
    <div className="col-md-6">
      <div className="form-group">
        <label>Contact Number:</label>
        <input
          type="tel"
          name="contactNumber"
          // value={formDataTrade.contactNumber}
          onChange={handleChangeTrade}
          className="form-control"
          placeholder="Enter Contact Number"
          required
        />
      </div>
    </div>
  </div>
  <div className="row">
    <div className="col-md-6">
      <div className="form-group">
        <label>Trading Contact Number:</label>
        <input
          type="tel"
          name="tradingContactNumber"
          // value={formDataTrade.tradingContactNumber}
          onChange={handleChangeTrade}
          className="form-control"
          placeholder="Enter Trading Contact Number"
          required
        />
      </div>
    </div>
    <div className="col-md-6">
      <div className="form-group">
        <label>Trading Address:</label>
        <input
          type="text"
          name="tradingAddress"
          // value={formDataTrade.tradingAddress}
          onChange={handleChangeTrade}
          className="form-control"
          placeholder="Enter Trading Address"
          required
        />
      </div>
    </div>
  </div>
  <div className="form-group">
    <label>Do you currently sell phones?</label> 
     <textarea
      name="currentlySellingPhones" 
      onChange={handleChangeTrade}
      className="form-control"
      placeholder="Do You Selling Phones Currently?"
    />
  </div>
  <div className="form-group">
    <label>Can you provide more information on it?</label>
    
    <textarea
      name="moreInformation"
      // value={formDataTrade.moreInformation}
      onChange={handleChangeTrade}
      className="form-control"
      placeholder="Enter More Information"
    />
  </div>
  <button type="submit" className="btn btn-primary" onClick={handleSubmitTrade}>{'Submit Form'}</button>
</>
 
                        )}

                        </>
                        ):(
                          <>
                            <h2>Verify Email</h2>
                            <input type="text" placeholder="Enter OTP" name="OTP" onChange={(e) => setOtpForVerification(e.target.value)} />
                            <button className="btn btn-primary" onClick={VerifyOTPFun} >Verify Email</button>

                            <br/>
                            <p>Please Check the Email, and Enter the OTP</p>
                          </>
                        ) }

                    </div>


                   
                </div>
            </div>
            <Footer />
            <ToastContainer /> {/* Added ToastContainer for toast notifications */}
        </>
    )
}
export default Singup;