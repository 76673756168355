import React, { useEffect } from "react";
import Sidebar from "./Sidebar/Sidebar";
import EditableCard from "./Userprofile";
import Useremail from "./Useremail";
import Useraddressinfo from "./Useraddressinfo";
import Userpassword from "./Userpassword";
import Navbar from "../Navbar/Navbar";
import { useNavigate } from "react-router";
import Env from "../Environment/Env";
import axios from "axios";
// import { Card, Button } from 'react-bootstrap';

const InActiveUserDD = () => {
    
    

    return (
        <>
            <Navbar />
        
 
<br/>
<br/>
<br/>
<br/>
            <div style={{ textAlign: 'center', marginTop: '50px' }}>
            <h1 style={{fontWeight:"bolder" , color:"red"}}>User Not Verified</h1>
            <h5 style={{fontWeight:"bolder"}}>Please wait for the Admin to verify your account</h5>
        </div>


        </>
    )
}

export default InActiveUserDD;