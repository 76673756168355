import React from 'react';
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";

function FAQs() {
  return (
    <>
    <Navbar />
    <div className="container pt-5 pb-5">
      <div className="row">
        <div className="col">
          <h2>Frequently Asked Questions (FAQs)</h2>
          <p>Welcome to the Mobile Bitz FAQs page, where we address common inquiries to provide you with the information you need. If you have any questions that are not covered here, feel free to contact us, and our dedicated team will be happy to assist you.</p>
        </div>
      </div>

      <div className="row">
        <div className="col">
          <h3>1. What types of mobile accessories do you offer?</h3>
          <p>At Mobile Bitz, we offer a wide range of mobile accessories, including cases, screen protectors, chargers, cables, headphones, power banks, and more. Whether you're looking for protection, style, or functionality, we have the perfect accessories to enhance your mobile experience.</p>
        </div>
      </div>

      <div className="row">
        <div className="col">
          <h3>2. Do you provide repair services for mobile devices?</h3>
          <p>Yes, Mobile Bitz provides professional repair services for smartphones, tablets, and other electronic devices. Our skilled technicians are trained to diagnose and repair various issues, including cracked screens, battery replacements, charging port repairs, and more. Visit one of our branches or contact us for more information on our repair services.</p>
        </div>
      </div>

      <div className="row">
        <div className="col">
          <h3>3. How long does it take to repair a mobile device?</h3>
          <p>The repair time depends on the nature of the issue and the availability of parts. Our technicians strive to complete repairs as quickly as possible without compromising quality. For most common repairs, such as screen replacements, the turnaround time is typically within a few hours. However, more complex repairs may take longer. We'll provide you with an estimated repair time when you bring in your device.</p>
        </div>
      </div>

      <div className="row">
        <div className="col">
          <h3>4. Can I purchase mobile accessories online?</h3>
          <p>Yes, you can browse and purchase mobile accessories from our online store. Simply visit our website, explore our product catalog, and place your order. We offer secure payment options and convenient delivery services to ensure a smooth shopping experience.</p>
        </div>
      </div>

      <div className="row">
        <div className="col">
          <h3>5. Do you offer warranty on your products and services?</h3>
          <p>Yes, we stand behind the quality of our products and services. Most of our mobile accessories come with a manufacturer's warranty against defects in materials and workmanship. Additionally, we provide a warranty on our repair services to guarantee the quality of our workmanship. Please refer to the warranty terms and conditions for specific details.</p>
        </div>
      </div>

      <div className="row">
        <div className="col">
          <h3>6. How can I track my online order?</h3>
          <p>Once your order is processed and shipped, you will receive a tracking number via email or SMS. You can use this tracking number to monitor the status of your delivery and estimated delivery date.</p>
        </div>
      </div>

      <div className="row">
        <div className="col">
          <h3>7. What payment methods do you accept?</h3>
          <p>We accept various payment methods, including credit/debit cards, PayPal, and other secure online payment options. You can choose your preferred payment method during the checkout process.</p>
        </div>
      </div>

      <div className="row">
        <div className="col">
          <h3>8. Can I return or exchange a product if I'm not satisfied?</h3>
          <p>Yes, we offer a hassle-free return and exchange policy for eligible products. If you're not satisfied with your purchase, you can return it within the specified return period for a refund or exchange. Please review our return policy for detailed instructions and conditions.</p>
        </div>
      </div>

      <div className="row">
        <div className="col">
          <h3>9. How can I contact customer support for assistance?</h3>
          <p>If you have any questions, concerns, or need assistance, our customer support team is here to help. You can contact us via phone, email, or visit one of our branches for in-person assistance. Our friendly and knowledgeable staff will be happy to assist you with any inquiries or issues you may have.</p>
        </div>
      </div>
    </div>
    <Footer />
    </>
  );
}

export default FAQs;
