import React, { useEffect } from "react";
import Sidebar from "./Sidebar/Sidebar";
import EditableCard from "./Userprofile";
import Useremail from "./Useremail";
import Userpassword from "./Userpassword";
import Navbar from "../Navbar/Navbar";
import { useNavigate } from "react-router";
// import { Card, Button } from 'react-bootstrap';

const BillingAddress = () => {
    const navigate = useNavigate();
    let user = localStorage.getItem("role");

    useEffect(() => {
        if (user === "admin") {
            navigate("/");
        }
    }, [user]);

    return (
        <>
            <Navbar />
        
 

            <div style={{ backgroundColor: 'rgb(249,249,249)' }} className="container-fluid">
                <div className="row">
                    {/* Sidebar takes up 4 columns */}
                    <div className="col-md-4">
                        <Sidebar />
                    </div>

                    {/* EditableCard takes up 6 columns */}
                    <div className="col-md-6 mt-5">
                        <h3>Billing Address</h3>
                        <div className="overlay-content ">
                            <h5>Show all the addresses that you include as your billing address...</h5>
                            {/* <Userpassword /> */} 

                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}

export default BillingAddress;